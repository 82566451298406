<div class="small-footer">
  <div class="container-fluid">
    <div class="footer-info">
      <div class="copyright-content">
        <p class="copyright-text">
          &copy; {{ getCurrentYear() }}
          <ng-container i18n="@@allRightsReserved"
            >All rights reserved</ng-container
          >
        </p>
      </div>
      <div class="group">
        <a [href]="'tel:'+contacts?.contacts?.contact_phone?.replaceAll(' ' , '')">
        <div
          class="contact-container phone"
          *ngIf="!isContactLinksLoading && contacts?.contacts?.contact_phone"
        >
          <img src="./assets/images/phone.svg" alt="phone-logo" />
            <span class="keep-ltr">{{ maskNumber(contacts?.contacts?.contact_phone , [4,9]) }}</span>
          </div>
        </a>
        <a
          class="contact-container email"
          *ngIf="!isContactLinksLoading && contacts?.contacts?.contact_email"
          href="mailto:{{ contacts?.contacts?.contact_email}}"target="_blank"
        >
          <img src="./assets/images/email.svg" alt="email-logo" />
          <span>{{ contacts?.contacts?.contact_email }}</span>
        </a>
      </div>
      <div *ngIf="isContactLinksLoading" class="group-loader w-100" style="display: flex;gap: 10px;max-width:460px">
        <div class="loader" style="width: 50%;">
          <ngx-skeleton-loader
          [theme]="{
            'border-radius': '5px',
            'max-width':'220px',
            width: '100%',
            height: '72px'
          }"
        ></ngx-skeleton-loader>
        </div>
        <div class="loader" style="width: 50%;">
          <ngx-skeleton-loader
          [theme]="{
            'border-radius': '5px',
            'max-width':'220px',
            width: '100%',
            height: '72px'
          }"
        ></ngx-skeleton-loader>
        </div>
      </div>
      <div class="info-container">
        <!-- <a i18n="@@jobs">Jobs</a>
        <span>-</span> -->
        <a [routerLink]="['/page/', 'privacy-policy']" i18n="@@policy">Policy</a>
        <span>-</span>
        <a [routerLink]="['/page/', 'privacy-policy']" i18n="@@privacyPolicy">Privacy Policy</a>
      </div>
      <div class="group stores">
        <div class="logo">
          <a href="https://play.google.com/store/apps/details?id=com.mcit.imackup&pcampaignid=web_share" target="_blank" rel="noopener noreferrer">
            <img src="./assets/images/google-play.png" alt="google-play-logo" />
          </a>
        </div>
        <div class="logo">
          <a href="https://apps.apple.com/sa/app/i-makeup-%D8%A2%D9%8A-%D9%85%D9%8A%D9%83-%D8%A7%D8%A8/id1662317896" target="_blank" rel="noopener noreferrer">
            <img src="./assets/images/app-store.png" alt="app-store-logo" />
          </a>
        </div>
      </div>
    </div>
  </div>
</div>
