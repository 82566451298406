import { APP_ID, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { SimpleNotificationsModule } from 'angular2-notifications';
import { AuthComponent } from './modules/auth/auth.component';
import { NotFoundPageComponent } from './pages/not-found-page/not-found-page.component';
import { PrivacyPageComponent } from './pages/privacy-page/privacy-page.component';
import { CmsPagesComponent } from './pages/cms-pages/cms-pages.component';
import { TooltipModule } from 'ngx-bootstrap/tooltip';
import { ModalModule } from 'ngx-bootstrap/modal';
import { SignInComponent } from './modules/auth/components/sign-in/sign-in.component';
import { SignUpComponent } from './modules/auth/components/sign-up/sign-up.component';
import { VerifyPhoneNumberComponent } from './modules/auth/components/verify-phone-number/verify-phone-number.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgxMaskModule } from 'ngx-mask';
import { GiftCardComponent } from './pages/gift-card-page/gift-card.component';
import { HelpAndSupportComponent } from './pages/help-and-support/help-and-support.component';

import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { RECAPTCHA_V3_SITE_KEY, RecaptchaV3Module } from 'ng-recaptcha';
import { environment } from 'src/environments/environment';
import { AngularFireModule } from '@angular/fire/compat';
import { AngularFireAuthModule } from '@angular/fire/compat/auth';
import { AngularFirestoreModule } from '@angular/fire/compat/firestore';
import { UserInterceptorInterceptor } from './core/interceptors/user-interceptor.interceptor';
import { LanguageInterceptor } from './core/interceptors/language.interceptor';
import { FaqsComponent } from './pages/faqs/faqs.component';
import { AccordionModule } from 'ngx-bootstrap/accordion';
import { ErrorCodesInterceptor } from './core/interceptors/error-codes.interceptor';
import { NgOtpInputModule } from 'ng-otp-input';
import { CustomNotificationComponent } from './shared/components/custom-notification/custom-notification.component';
import { FormErrorsComponent } from './shared/components/form-errors/form-errors.component';
import { NgxSkeletonLoaderModule } from 'ngx-skeleton-loader';
import { FooterComponent } from "./shared/components/footer/footer.component";
import { MainLayoutModule } from './modules/main-layout/main-layout.module';
import { NgxLoaderComponent } from './shared/components/ngx-loader/ngx-loader.component';

export function getLocalStorage() {
  return typeof window !== 'undefined' ? window.localStorage : null;
}

@NgModule({
  declarations: [
    AppComponent,
    AuthComponent,
    NotFoundPageComponent,
    PrivacyPageComponent,
    CmsPagesComponent,
    FaqsComponent,
    SignInComponent,
    SignUpComponent,
    VerifyPhoneNumberComponent,
    GiftCardComponent,
    HelpAndSupportComponent,
  ],
  imports: [
    BrowserModule.withServerTransition({ appId: 'IMakeUp' }),
    AppRoutingModule,
    TooltipModule,
    NgOtpInputModule,
    FormsModule,
    HttpClientModule,
    ReactiveFormsModule,
    ModalModule.forRoot(),
    NgxMaskModule.forRoot(),
    CustomNotificationComponent,
    FormErrorsComponent,
    BrowserAnimationsModule,
    NgxSkeletonLoaderModule,
    SimpleNotificationsModule.forRoot({
        clickIconToClose: true,
    }),
    RecaptchaV3Module,
    AngularFireModule.initializeApp(environment.firebaseConfig),
    AngularFireAuthModule,
    AngularFirestoreModule,
    HttpClientModule,
    AccordionModule,
    MainLayoutModule,
    FooterComponent,
    NgxLoaderComponent
],
  providers: [
    { provide: 'CACHE_KEY', useValue: '' },
    {
      provide: RECAPTCHA_V3_SITE_KEY,
      useValue: environment.recaptcha.siteKey,
    },
    { provide: APP_ID,  useValue: 'IMakeUp' },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: UserInterceptorInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: LanguageInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: ErrorCodesInterceptor,
      multi: true,
    },
    { provide: 'LOCALSTORAGE', useFactory: getLocalStorage },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
