<ngx-loading
  [show]="loading"
  [config]="{
    animationType: ngxLoadingAnimationTypes.chasingDots,
    primaryColour: primaryColour,
    secondaryColour: secondaryColour,
    backdropBackgroundColour:'rgb(43 27 68 / 25%)',
    backdropBorderRadius: '3px'
  }"
  [template]="loadingTemplate"
></ngx-loading>
