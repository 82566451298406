import { DOCUMENT } from '@angular/common';
import { Inject, Injectable, Renderer2, RendererFactory2 } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class DirectionService {
  
  renderer!: Renderer2;
  private pageDirectionSubject: BehaviorSubject<'ltr' | 'rtl'> = new BehaviorSubject<'ltr' | 'rtl'>('ltr');
  constructor(private rendererFactory: RendererFactory2,
    @Inject(DOCUMENT) private document: Document

  ) {
    this.renderer = rendererFactory.createRenderer(null, null);
  }
  setPageDirection(direction: 'ltr' | 'rtl') {
    this.renderer.setAttribute(this.document.documentElement, 'dir', direction);
    this.pageDirectionSubject.next(direction);
  }
  getPageDirection() {
    return this.pageDirectionSubject.asObservable();
  }
}
