import { Component, inject, OnDestroy, OnInit, PLATFORM_ID } from '@angular/core';
import { CommonModule, isPlatformServer } from '@angular/common';
import { AccordionModule } from 'ngx-bootstrap/accordion';
import { HttpService } from 'src/app/core/services/http.service';
import { OutputDto } from 'src/app/shared/models/interfaces/output-dto';
import { FaqItem } from '../models/interfaces/pages';
import {
  BehaviorSubject,
  Subscription,
  debounceTime,
  switchMap,
  tap,
} from 'rxjs';
import { ActivatedRoute, Router } from '@angular/router';
import { BackgroundColorService } from 'src/app/core/services/background-color.service';
import { ShowFooterService } from 'src/app/core/services/show-footer.service';
import { ErrorHandlingService } from 'src/app/core/services/error-handling.service';

@Component({
  selector: 'app-faqs',
  templateUrl: './faqs.component.html',
  styleUrls: ['./faqs.component.scss'],
})
export class FaqsComponent implements OnInit, OnDestroy {
  loadingSchema: any[] = ['', '', '', '', '', '', '', ''];
  faqItemsList: FaqItem[] | undefined;
  errorMsg: string = '';
  emptyMsg: string = $localize`:@@emptyFaqMsg:No frequently asked questions available at the moment. You can contact our support team for any inquiries.`;

  loadingData: boolean = true;
  searchInput = new BehaviorSubject('');
  searchKeyword = '';
  filterParams: any = {};
  currentTab = 1;
  isLoadingFaqsCategories: boolean = true;
  categories: any = [];
  platform_id:Object = inject(PLATFORM_ID);
  private queryParamsSubscription!: Subscription;
  constructor(
    private httpService: HttpService,
    private backgroundColor: BackgroundColorService,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private _errorHandling : ErrorHandlingService ,
    private showFooter: ShowFooterService,
  ) {}
  ngOnInit() {
    this.backgroundColor.setBackgroundColor({ home: '#FDFDFD', navbarAllCategories: '#F9F9F9', navbarItems: '#F9F9F9' , navbarBg:'#FDFDFD' });
    // incase server rendering
    if(isPlatformServer(this.platform_id)){return}
    this.getFaqsData();
    this.getFaqsCategories();
    const selectedCategoryId =
      +this.activatedRoute.snapshot.queryParams['filter_by_category'];
    if (selectedCategoryId) {
      this.currentTab = selectedCategoryId;
    } else {
      this.router.navigate([], {
        queryParams: { filter_by_category: 1 },
        queryParamsHandling: 'merge',
      });
    }
    this.showFooter.setShowFooterState()
  }

  getFaqsData() {
    this.queryParamsSubscription = this.activatedRoute.queryParams
      .pipe(
        tap((params) => {
          this.loadingData = true;
        }),
        switchMap((params) => {
          this.searchKeyword = params['search'] || '';
          this.filterParams['search'] = this.searchKeyword;
          const categoryId = params['filter_by_category'];
          if (categoryId) {
            this.filterParams['filter_by_category'] = categoryId;
          }
          return this.httpService.getReq(`/cms-page/faqs`, { params: params });
        })
      )
      .subscribe({
        next: (resp: OutputDto<FaqItem[]>) => {
          this.faqItemsList = resp?.data;
          this.errorMsg = '';
          this.loadingData = false;
        },
        error: (error) => {
          this.errorMsg = error?.message;
          this.loadingData = false;
          this.faqItemsList = [];
        },
      });
  }

  search() {
    this.searchInput.next(this.searchKeyword);
    this.searchInput.pipe(debounceTime(300)).subscribe((value: String) => {
      if (this.searchKeyword.length > 2 || this.searchKeyword.length == 0) {
        if (this.searchKeyword) {
          this.filterParams['search'] = this.searchKeyword;
        } else {
          delete this.filterParams['search'];
        }
        this.router.navigate([], { queryParams: this.filterParams });
      }
    });
  }

  onChangeTab(category: any) {
    this.currentTab = category.id;
    this.router.navigate([], {
      queryParams: { filter_by_category: category.id },
      queryParamsHandling: 'merge',
    });
  }


  getFaqsCategories() {
    this.isLoadingFaqsCategories = true;
    this.httpService.getReq('/cms-page/faqs/categories').subscribe({
      next: (res) => {
        this.isLoadingFaqsCategories = false;
        this.categories = res?.data || [];
      },
      error: (error) => {
        this.isLoadingFaqsCategories = false;
        this.errorMsg = error?.message;
        this._errorHandling.errorHandling(error) ;
      },
    });
  }

  ngOnDestroy(): void {
    // incase server rendering
    if(isPlatformServer(this.platform_id)){return}
    this.queryParamsSubscription.unsubscribe();
  }
}
