import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { MainLayoutRoutingModule } from './main-layout-routing.module';
import { MainLayoutComponent } from './main-layout.component';
import { FooterComponent } from 'src/app/shared/components/footer/footer.component';
import { NavbarComponent } from './navbar/navbar.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ProductDetailsPathPipe } from 'src/app/shared/pipes/product-details-path.pipe';
import { NgxSkeletonLoaderModule } from 'ngx-skeleton-loader';
import { NavbarScrolledComponent } from 'src/app/shared/components/navbar-scrolled/navbar-scrolled.component';
import { FooterNavsComponent } from 'src/app/shared/components/footer-navs/footer-navs.component';


@NgModule({
  declarations: [
    MainLayoutComponent,
    NavbarComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    NgxSkeletonLoaderModule,
    NavbarScrolledComponent,
    FooterNavsComponent,
    ReactiveFormsModule,
    ProductDetailsPathPipe,
    FooterComponent,
    MainLayoutRoutingModule,
],
exports:[NavbarComponent]
})
export class MainLayoutModule { }
