import { AfterViewInit, Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { NavigationEnd, RouterModule } from '@angular/router';
import { CommonModule, isPlatformBrowser } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { ProductDetailsPathPipe } from '../../pipes/product-details-path.pipe';
import { NavbarComponent } from 'src/app/modules/main-layout/navbar/navbar.component';

@Component({
  standalone:true,
  selector: 'app-footer-navs',
  templateUrl: './footer-navs.component.html',
  styleUrls: ['./footer-navs.component.scss'],
  imports:[
    CommonModule,
    RouterModule,
    FormsModule,
    ProductDetailsPathPipe
  ]
})
export class FooterNavsComponent extends NavbarComponent implements OnInit,AfterViewInit {
  hideTopNav: boolean = false ;
  // isMyAccountPage: boolean = false ;
  override ngOnInit(): void {
    this.accountService.cartCountItem.subscribe((data)=>{
      this.cartCountItems = data
    })
    this.activatedRoute.queryParams.subscribe(
      (param)=>{
        this.searchNav.search = param['search'] || ''
      }
    )

    this.bgColorSubscription = this.backgroundColor.backgroundColor$.subscribe(
      (color) => {
        this.navbarBg = { 'background-color': this.router.url.includes('cart') ? "#fafafa" : color?.navbarBg };
        this.routeChangeSubscription = this.router.events.subscribe((event) => {
          this.hideTopNav = this.router.url === '/checkout' ? true : false ;
          // this.isMyAccountPage = this.router.url === '/account/my-account' ? true : false ;
          if (event instanceof NavigationEnd) {
            this.navbarBg = { 'background-color': this.router.url.includes('cart') ? "#fafafa" : color?.navbarBg };
          }
        });
      }
    );

  }
  override ngAfterViewInit(): void {
    if(isPlatformBrowser(this.platform_id)){
      this.searchNav.overLay = this.overLayerRef.nativeElement
      // this.searchNav.settingSearch();
    }
  }
  @ViewChild('resultList') override resultList!: ElementRef;
  @ViewChild('overLayer')  overLayer!: ElementRef;
  @ViewChild('mobileSearchInput') mobileSearchInput: ElementRef | undefined;
  enterPressed(e:any){
    if(e.key == 'Enter'){
      this.searchNav.showResultPopUp = false;
      // this.resultList.nativeElement.style.display = "none" ;
      this.overLayer.nativeElement.style.display='none';
      // this.searchNav.searchResults=[];
      this.searchNav.routeWithSearchQueryParam();
      this.mobileSearchInput?.nativeElement.blur();
    }
    
  }
}
