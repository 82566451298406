import { isPlatformBrowser } from '@angular/common';
import { AfterViewInit, Component, inject, makeStateKey, OnInit, PLATFORM_ID, TransferState, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ErrorHandlingService } from 'src/app/core/services/error-handling.service';
import { HttpService } from 'src/app/core/services/http.service';
import { MetaDataService } from 'src/app/core/services/meta-data/meta-data.service';
import { ShowFooterService } from 'src/app/core/services/show-footer.service';

@Component({
  selector: 'app-cms-pages',
  templateUrl: './cms-pages.component.html',
  styleUrls: ['./cms-pages.component.scss']
})
export class CmsPagesComponent implements AfterViewInit{
  @ViewChild('page')  page!:any;
  isLoading: boolean = false;
  serverErrorFetch: string|null = null;
  notFound: boolean = false;
  noData: boolean = false;
  loadingSchema: any[] = ['','','','','','','','',''];
  platform_id:Object = inject(PLATFORM_ID);
  constructor(
    private _errorHandling : ErrorHandlingService ,
    private activatedRoute:ActivatedRoute,
    private _api:HttpService,
    private _transferState:TransferState,
    private metaDataService: MetaDataService,
    private showFooter: ShowFooterService,
    ){}
  ngAfterViewInit(){
    // console.log("activatedRoute",this.page)
    if(isPlatformBrowser(this.platform_id)){
      this.activatedRoute.params.subscribe((params)=>{
        this.getPage(params['url']?.split('.html')[0])
      })
    }
    this.showFooter.setShowFooterState()
  }
  getPage(url:any){
    // console.log(url)
    // if(isPlatformBrowser(this.platform_id)){
    //   let cms_page_res = this._transferState.get(makeStateKey("cms_page_res"),undefined);
    //   // console.log("_transferState",cms_page_res)
    //   if(cms_page_res){
    //     this.handleResponseOfCRM(cms_page_res);
    //     this._transferState.remove(makeStateKey('cms_page_res'));
    //     return
    //   }
    // }

    this.isLoading = true;
    this.notFound = false;
    this.noData = false;
    this.serverErrorFetch = null;

    this.metaDataService.updatePageTitle(`... | ${this.metaDataService.titleTranslate}`);
    this._api.getReq(`/cms/${url}`).subscribe(
      (res)=>{
        // console.log("getReq",res)
        this.handleResponseOfCRM(res);
      },
      (err)=>{
        this._errorHandling.errorHandling(err) ;
        this.serverErrorFetch = err?.error?.message || $localize`:@@generalError:Something wrong happened, please try again later`;
        this.isLoading = false;
      }
    )
  }

  handleResponseOfCRM(res:any){
    // console.log("handle",res)
    if(isPlatformBrowser(this.platform_id)){
      if(res?.error?.message == "Not Found"){
        this.isLoading = false;
        this.notFound = true;
        this.metaDataService.updatePageTitle('404');
        return;
      }
      if(!res?.data.html_content){
        this.noData = true;
        this.isLoading = false;
        return;
      }
      // console.log("handle html content",res?.data.html_content)
      this.page.nativeElement.innerHTML = res?.data.html_content;

    }else{
      this.metaDataService.updateMetaData(
        res?.data?.meta_title ? res?.data?.meta_title : `CMS | ${this.metaDataService.titleTranslate}`,
        res?.data?.meta_description,'',res?.data?.meta_keywords);
      this.metaDataService.updatePageTitle(res?.data?.meta_title ? res?.data?.meta_title : `CMS | ${this.metaDataService.titleTranslate}`);
      this._transferState.set(makeStateKey("cms_page_res"),res);
    }

    this.isLoading = false;
  }

}
