<div class="help-and-support-container">
  <div class="section">
    <div class="header">
      <div class="help-img"><img src="./assets/icons/helperAndSupport.svg" alt="helperAndSupport"></div>
      <h3 class="title" i18n="@@helpSupport">Help And Support</h3>
    </div>
    <div class="helpers">
      <div class="help" [routerLink]="['/account/orders/delivered.html']" routerLinkActive="router-link-active" >
        <div class="icon"><img src="./assets/icons/delivery-order-return.svg" alt=""></div>
        <span class="name" i18n="@@deliveryOrderDiscard">Delivery, order or discard</span>
      </div>
      <div class="help" [routerLink]="['/account/my-account.html']" routerLinkActive="router-link-active" >
        <div class="icon"><img src="./assets/icons/account-privacy-settings.svg" alt=""></div>
        <span class="name" i18n="@@accountPrivacySettings">Account and privacy settings</span>
      </div>
    </div>
  </div>

  <div class="section" style="margin-top: 150px;">
    <div class="horizontal-line"></div>
    <div class="supports">
      <a [href]="'tel:'+footerData?.contacts?.contact_phone?.replaceAll(' ' , '')" class="support robot">
        <div class="icon"><img src="./assets/icons/robot.svg" alt=""></div>
        <span class="name" i18n="@@talkCustomerService">Talk to customer service</span>
      </a>
      <a href="mailto:{{footerData?.contacts?.contact_email}}?subject={{helpSupportTran}}&amp;body="
        target="_blank" class="support send-email" [tooltip]="isLoading ? waitTranslate: ''">
        <div class="icon"><img src="./assets/icons/send-email.svg" alt=""></div>
        <span class="name"i18n="@@askViaEmail">Ask via e-mail</span>
      </a>
      <a [href]="'https://wa.me/'+footerData?.contacts?.contact_phone?.replaceAll(' ','')" class="support whatsup" target="_blank">
        <div class="icon"><img src="./assets/icons/whatsup.svg" alt=""></div>
        <span class="name" i18n="@@CommunicateWhatsApp">Communicate on WhatsApp</span>
      </a>
    </div>
  </div>

</div>
