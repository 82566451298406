import { AfterViewInit, Component, Input, OnInit } from '@angular/core';
import { contactInfo, mailObj } from '../footer/footer.component';
import { NavigationEnd, RouterModule } from '@angular/router';
import { Subject } from 'rxjs';
import { CommonModule, isPlatformBrowser } from '@angular/common';
import { NgxSkeletonLoaderModule } from 'ngx-skeleton-loader';
import { FormsModule } from '@angular/forms';
import { ProductDetailsPathPipe } from '../../pipes/product-details-path.pipe';
import { NavbarComponent, NavCategories$ } from 'src/app/modules/main-layout/navbar/navbar.component';
import { SearchNavService } from 'src/app/modules/main-layout/navbar/search-nav.service';

@Component({
  standalone:true,
  selector: 'app-navbar-scrolled',
  templateUrl: './navbar-scrolled.component.html',
  styleUrls: ['./navbar-scrolled.component.scss'],
  imports:[
    CommonModule,
    RouterModule,
    FormsModule,
    ProductDetailsPathPipe,
    NgxSkeletonLoaderModule
  ]
})
export class NavbarScrolledComponent extends NavbarComponent implements OnInit, AfterViewInit {
  @Input() categoriesData: any
  override ngOnInit(): void {
    this.accountService.cartCountItem.subscribe((data)=>{
      this.cartCountItems = data
    })
    this.loadingArray = ['', '',''];
    this.activatedRoute.queryParams.subscribe(
      (param)=>{
        this.searchNav.search = param['search'] || ''
      }
    )
    this.bgColorSubscription = this.backgroundColor.backgroundColor$.subscribe(
      (color) => {
        this.allCategoriesBg = {
          'background-color': color?.navbarAllCategories,
        };
        this.navbarBg = { 'background-color': this.router.url.includes('cart') ? "#fafafa" : color?.navbarBg };
        this.categoriesBg = { 'background-color': color?.navbarItems };
        this.routeChangeSubscription = this.router.events.subscribe((event) => {
          if (event instanceof NavigationEnd) {
            this.navbarBg = { 'background-color': this.router.url.includes('cart') ? "#fafafa" : color?.navbarBg };
          }
        });
      }
    );
    // get all categories
    if(isPlatformBrowser(this.platform_id)){
      this.isLoadingCategories = true;
      if(this.categoriesData){
        this.responseOfAllCategories(this.categoriesData);
      }
      NavCategories$.subscribe(res=>{
        this.responseOfAllCategories(res);
      })
    }

    this.detectRoutes(this.router?.url);
    this.router.events.subscribe((event: any) => {
      if (event instanceof NavigationEnd) {
        if (this.router.url.indexOf('category') == -1) {
          this.currentCategorySelected =
            this.mainCateName?.length > 0 ? this.mainCateName[0] : '';
          this.isAllCategoriesHovered = this.mainCateName[0];
        } else {
          let slug: string = this.router.url.split('/')[3];
          this.currentCategorySelected = this.categories.filter(
            (category: any) => category?.slug == slug
          )[0]?.name;
          this.isAllCategoriesHovered = this.currentCategorySelected;
        }
        this.detectRoutes(event?.url);
      }
    });
    this.userName = this._auth.getUserObj()?.name;
    this.authSubscription = this._auth.isAuthenticatedSubject.subscribe(
      (isAuthenticated) => {
        this.userName = this._auth.getUserObj()?.name;
        this.isAuth = isAuthenticated;
      }
    );

    this.$accountServiceSubscription = this.accountService?.hideCategoryNav$.subscribe((resp:boolean)=>{
      this.hideCategoriesSection = resp;
    })
  }
  override ngAfterViewInit(): void {
    if(isPlatformBrowser(this.platform_id)){
      this.searchNav.overLay = this.overLayerRef.nativeElement
      // this.searchNav.settingSearch();
    }
  }

}