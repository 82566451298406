import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Subject, catchError, debounceTime, of, switchMap, tap } from 'rxjs';
import { AuthService } from 'src/app/core/services/auth.service';
import { HttpService } from 'src/app/core/services/http.service';
import { OutputDto } from 'src/app/shared/models/interfaces/output-dto';

@Injectable({
  providedIn: 'root'
})
export class SearchNavService {
  search = '';
  overLay: any
  searchTerms = new Subject<string>();
  searching : boolean = false;
  searchResults : any[] = [] ;
  searchIsFinish = new Subject<boolean>();
  metaDataSearchResult:any;
  noResults : boolean = false ;
  searchError : string = '' ;
  showResultPopUp : boolean = false 
  callFromFooterNav : boolean = false ;
  constructor(
    private _auth: AuthService,
    private _api: HttpService,
    private router: Router
    ) { 
    }

  settingSearch(){
    this.searchTerms.pipe(
      tap(()=>{
        this.searching = true;
        this.searchResults = [];
      }),
      debounceTime(300),
      switchMap((searchQuery:string)=>{
        const limit = this.callFromFooterNav ? 12 : 5 ;
        let params = {'search' : searchQuery, 'paginate' : 0,'limit':limit} ;
        // console.log(searchQuery)
        return this._api.getReqV2('/products' , {params : params}).pipe(
          catchError((err)=>{
            this.searching = false;
              // overLay.classList.remove('d-none');
              this.searchError = (err?.status === 500 || err?.status === 400 || err?.status === 0) ?
              $localize`:@@serverErrorMessage:Something wrong happened, please try again later`
              : err?.message;
              this.noResults = false ;
          return of(null);
          })
        )
    })).subscribe(
      (res: OutputDto<any>) => {
        this.metaDataSearchResult = res?.meta;
        let items = res.data?.map((e:any)=>{
          return {
            "item_id":e.id,
            "name":e.name,
            // "discount":e.discount,
            "price":e.price,
            // "item_category":e?.category[0]?.category_parents[0],
            // "item_category2":e?.category[0]?.category_parents[1]
          }
        })
        window.gtag("event", "content_view", {
          currency: "SAR",
          items: items,
          userEmail: this._auth.getUserObj().email,
          phone: this._auth.getUserObj().phone
        })

        if(this.search === ''){
          this.searchResults = [] ;
          this.noResults = false ;
          // this.showResultPopUp = false
        }else{
          // this.showResultPopUp = true
          this.searchResults = res?.data ? res?.data : [];
          if(!res){
            if(this.searchResults.length === 0 ){
              this.noResults = true ;
              this.overLay.classList.add('d-none');
            }
          }
          else{
            if(this.searchResults.length === 0 ){
              this.noResults = true ;
              this.overLay.classList.remove('d-none');
            }else{
              this.noResults = false ;
              // list.classList.remove('d-none') ;
              this.overLay.classList.remove('d-none') ;
            }
          }
        }
        this.searching = false;
      }
    );    
  }
  routeWithSearchQueryParam(){
    window.gtag("event", "search", {
      search_term: this.search,
      email: this._auth.getUserObj()?.email,
      phone: this._auth.getUserObj()?.phone,

    });
    if(this.searchResults.length == 1){
      let result = this.searchResults[0]
      let slug:string = result.category?.length > 0 ? result.category[0]?.slug : '';
      this.router.navigate(['/p/' + slug ,result?.url_key + '.html' ])
      return;
    }
    this.router.navigate([`/all-categories.html`],{queryParams : {search : this.search}})
  }
}
