import { Component, ElementRef, Inject, LOCALE_ID, OnInit, ViewChild,PLATFORM_ID, inject } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { MaskApplierService } from 'ngx-mask';
import { CustomNotificationService } from 'src/app/core/services/custom-notification.service';
import { ErrorHandlingService } from 'src/app/core/services/error-handling.service';
import { HttpService } from 'src/app/core/services/http.service';
import { ValidationService } from 'src/app/core/services/validation.service';
import { OutputDto } from 'src/app/shared/models/interfaces/output-dto';
import { CartService } from 'src/app/shared/services/cart.service';
// import { environment } from 'src/environments/environment';
import { isPlatformBrowser } from '@angular/common';
import { ShowFooterService } from 'src/app/core/services/show-footer.service';
import { take } from 'rxjs';

@Component({
  selector: 'app-gift-card',
  templateUrl: './gift-card.component.html',
  styleUrls: ['./gift-card.component.scss'],
})
export class GiftCardComponent implements OnInit {
  giftCardColors: string[] = [];
  mainGiftCardColor: string = '';
  senderName: string = '';
  isSenderNameHide: boolean = false;
  giftCardValues: string[] = [ '100 ' + $localize`:@@SAR:SAR`, '200 ' + $localize`:@@SAR:SAR`, '500 ' + $localize`:@@SAR:SAR`];
  otherPrice: string = $localize`:@@otherPrice:other price`;
  otherPriceValue: string ='';
  currency: string = $localize`:@@SAR:SAR`;
  giftValueMessage: string = $localize`:@@giftValueMessage:Select gift card value`;
  giftCardUpdatedMess: string = $localize`:@@giftCardUpdatedMess:Gift card updated successfully`;
  selectedCardValue: string = '';
  isLoadingGiftCartDesign:boolean=false;
  isLoading:boolean=false;
  giftCardId:number = 0;
  giftCardForm!: FormGroup;
  loadingSubmit: boolean = false;
  customCardValue:any = 0;
  value:number = 0;
  isSubmit:boolean = false;
  isAddedGiftCard:boolean = false;
  giftCartproductId:number=0;
  isMaxGiftCardValue:boolean = false;
  isMinGiftCardValue:boolean = false;
  platform_id:Object = inject(PLATFORM_ID);
  @ViewChild('customValueInput') customValueInput!: ElementRef<HTMLInputElement>;
  constructor(
    @Inject(LOCALE_ID) public lang: string,
    private validateService: ValidationService,
    private maskApplier : MaskApplierService,
    private cartService : CartService,
    private notifications: CustomNotificationService,
    private _errorHandling:ErrorHandlingService,
    private httpService : HttpService,
    private route:Router,
    private showFooter: ShowFooterService,
  ) {
    if(isPlatformBrowser(this.platform_id)){
      window.gtag('event', 'page_view', {
        page_title: 'GiftCard',
        page_location: window.location.href,
        page_path: window.location.pathname
      });
    }
    showFooter.setShowFooterState()
  }

  ngOnInit(): void {
    this.getGiftCartDesign();
    this.handleToGetVirtualItem();
    this.getGiftCardId();
    this.giftCardForm = new FormGroup({
    recipient_name: new FormControl('', [
      Validators.required,
      Validators.minLength(2),
      Validators.pattern(this.validateService.alphaPattern)]),
      recipient_email: new FormControl('', [
        Validators.required,
        Validators.email,
        Validators.pattern(this.validateService.emailPattern)]),
      phone_number: new FormControl('', [
      // Validators.required,
        this.validateService.validatePhoneNumber(),
      ]),
      sender_name: new FormControl('', [
        // Validators.required,
        Validators.minLength(2),
        Validators.pattern(this.validateService.alphaPattern)]),
        message: new FormControl('', [
        Validators.required,
        Validators.minLength(5),
        Validators.maxLength(25)]),
    });
    
  }
  changeCardColor(color: string) {
    this.mainGiftCardColor = color;
  }
  toggleNameVisibility() {
    this.isSenderNameHide = !this.isSenderNameHide;
  }
  selectCardValue(value: string) {
   this.selectedCardValue = value;
    this.getGiftCardValue(value)
    this.otherPriceValue = "";
  }

  getGiftCardValue(value:string){
    if (typeof value === 'string'){
      const selectedCardValue = value.split(" ");
      this.value = +selectedCardValue[0];
    }
    else{
      this.value = value;
    }
  }
  
  handleCustomGiftCardValue(customValue: string) {
    if (+customValue<=1000) {     
      this.customCardValue = customValue
      this.getGiftCardValue(customValue);
      this.isMaxGiftCardValue = false;
      this.isMinGiftCardValue = false
    }
    else{
      this.isMaxGiftCardValue = true;
      this.isMinGiftCardValue = false
    }
    let x = Number(customValue)
    if( x > 0 && x < 30){
      this.isMinGiftCardValue = true
    }else{
      this.isMinGiftCardValue = false
    }
  } 
  toggleGiftCardOtherPrice() {
    this.selectedCardValue = this.selectedCardValue === 'other price' ? '' : 'other price';
    if (this.selectedCardValue === 'other price') {
      setTimeout(() => {
        if (this.customValueInput) {
          this.customValueInput.nativeElement.value =  this.otherPriceValue;
        }
      }, 0);
    }
  }

  
  getGiftCardId(){
    this.httpService.getReq("/products/types/virtual").pipe(take(1)).subscribe({
      next: res=>{
          this.isLoadingGiftCartDesign = false;
            this.giftCartproductId=res?.data?.products[0].id;
      },error : error =>{
        // this.isLoadingGiftCartDesign = false;
        this._errorHandling.errorHandling(error);
      }
    })
  }

  addGiftCard(){
    // const productId = environment.productIdOfGiftCard;
    this.value = this.value ? this.value : +this.otherPriceValue ;
    this.isSubmit = true
    if (this.giftCardForm.invalid || !this.value ||!this.mainGiftCardColor || this.isMaxGiftCardValue || this.isMinGiftCardValue) {
      this.giftCardForm.markAllAsTouched();
      return;
    }
    this.loadingSubmit = true;
    let phone = this.giftCardForm.value.phone_number;
    let payload = {
      product_id:this.giftCartproductId,
      color:  this.mainGiftCardColor,
      receiver_name : this.giftCardForm?.value?.recipient_name,
      receiver_email : this.giftCardForm?.value?.recipient_email,
      receiver_phone_number: ('+966' + (phone.startsWith("0")? phone.slice(1):phone)).replaceAll(' ', ''),
        sender_name : this.giftCardForm?.value?.sender_name,
        gift_message :this.giftCardForm?.value?.message,
        quantity:this.value
      };
    if(this.isAddedGiftCard){
      this.cartService.removeProductToCart(this.giftCardId).subscribe(()=>{
        this.cartService.addProductTocart(this.giftCartproductId, payload).subscribe({
          next: (res: OutputDto<any>) => {
            this.loadingSubmit = false;
            this.notifications.showNotification({
              type: 'success',
              message:this.giftCardUpdatedMess,
            });
            this.route.navigate(['/cart.html']);
          },
          error: (err) => {
            this.loadingSubmit = false;
            this._errorHandling.errorHandling(err);
          },
        });
      });
    }
    else{
      this.cartService.addProductTocart(this.giftCartproductId, payload).subscribe({
        next: (res: OutputDto<any>) => {
          this.loadingSubmit = false;
          this.notifications.showNotification({
            type: 'add-cart',
            message: res.message || ''
          });
          this.route.navigate(['/cart.html']);
        },
        error: (err) => {
          this.loadingSubmit = false;
          this._errorHandling.errorHandling(err);
        },
      });
    }

  }
 
  getGiftCartDesign(){
    this.isLoadingGiftCartDesign = true;
    this.httpService.getReq("/core-configs/gift-cart-design").pipe(take(1)).subscribe({
      next: resp=>{
          this.isLoadingGiftCartDesign = false;
          this.giftCardColors = resp?.data?.colors;
          this.mainGiftCardColor = this.giftCardColors[0];
      },error : error =>{
        this.isLoadingGiftCartDesign = false;
        this.giftCardColors = ['#9F1E64', '#F277AA', '#666668', '#FFA533']; 
        this.mainGiftCardColor = this.giftCardColors[0];
        this._errorHandling.errorHandling(error);
      }
    })
  }

  handleToGetVirtualItem(){
    this.isLoading = true;
    this.httpService.getReq('/customer/cart').pipe(take(1)).subscribe(res=>{
      this.isLoading = false;
      const items = res?.data?.items
      if(items?.length>0){
        const virtualItems = items.filter((item:any) => item.type === 'virtual');
        if(virtualItems?.length === 1){
          this.isAddedGiftCard = true ;
          const giftCardData = virtualItems[0].additional;
          this.giftCardId = virtualItems[0].id ;
          const phone_number = giftCardData?.receiver_phone_number.slice(4);
          this.giftCardForm.patchValue({
            recipient_name: giftCardData?.receiver_name,
            recipient_email: giftCardData?.receiver_email,
            phone_number,
            sender_name: giftCardData?.sender_name,
            message: giftCardData?.gift_message,
          });
          this.mainGiftCardColor = giftCardData?.color;
          let matchFound = false;
          this.giftCardValues.forEach(value => {
            const numericValue = parseInt(value.split(' ')[0]);
            if (numericValue === giftCardData?.quantity) {
              this.selectedCardValue = value;
              this.value =  +(value.split(' ')[0]);
              matchFound = true;
            }
          });
          if (!matchFound) {
            this.selectedCardValue = 'other price';
            this.handleCustomGiftCardValue(this.otherPriceValue)
              setTimeout(() => {
              if (this.customValueInput) {
                this.otherPriceValue = giftCardData?.quantity.toString()
                this.customValueInput.nativeElement.value = this.otherPriceValue;
              }
            }, 0);
          }
          this.applyMasking(phone_number);
        }
     }
    },err=>{
      this._errorHandling.errorHandling(err);
      this.isLoading = false;
    })
  }

  applyMasking(phoneNumber:string){
    let phoneNumberLength = phoneNumber.length ;
    if(phoneNumber && phoneNumber.startsWith("05")&& phoneNumberLength === 12){
      this.giftCardForm.controls['phone_number'].setValue(this.maskApplier.applyMask(phoneNumber, '000 000 0000'));
    }
    else if(phoneNumber.startsWith("5") && phoneNumberLength === 11){
      this.giftCardForm.controls['phone_number'].setValue(this.maskApplier.applyMask(phoneNumber, '00 000 0000'));
    }
    else{
      this.giftCardForm.controls['phone_number'].setValue(this.maskApplier.applyMask(phoneNumber, '00 000 0000'));
    }
  }

  getMask(): string {
    const phoneNumber = this.giftCardForm.get('phone_number')?.value;
    let phoneNumberLength = phoneNumber.length ;
    if (phoneNumber && phoneNumber.startsWith('05')&& phoneNumberLength === 12) {
      return '000 000 0000'; 
    } else if (phoneNumber && phoneNumber.startsWith('5')&& phoneNumberLength === 11) {
      return '00 000 0000'; 
    } 
    else {
      return '00 000 0000';
    }
  }
}