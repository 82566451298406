
import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { MainLayoutComponent } from './main-layout.component';
import { NotFoundPageComponent } from 'src/app/pages/not-found-page/not-found-page.component';
import { GiftCardComponent } from 'src/app/pages/gift-card-page/gift-card.component';
import { HelpAndSupportComponent } from 'src/app/pages/help-and-support/help-and-support.component';
import { FaqsComponent } from 'src/app/pages/faqs/faqs.component';
import { CmsPagesComponent } from 'src/app/pages/cms-pages/cms-pages.component';


const routes: Routes = [
  {
    path: '',
    component: MainLayoutComponent,
    children: [
      { path: '', redirectTo: 'home.html', pathMatch: 'full' },
      {
        path: 'home.html',
        loadChildren: () =>
          import('../new-home/new-home.module').then((m) => m.NewHomeModule),
      },
      // {
      //   path: 'new-home',
      //   loadChildren: () =>
      //     import('../new-home/new-home.module').then((m) => m.NewHomeModule),
      // },

      {
        path: 'all-categories.html',
        loadComponent: () =>
          import('../products/components/products-all-categories-list/products-all-categories-list.component').then((m) => m.ProductsAllCategoriesListComponent),
      },
      {
        path: 'special-offers-sale.html',
        loadComponent: () =>
          import('../products/components/products-all-categories-list/products-all-categories-list.component').then((m) => m.ProductsAllCategoriesListComponent),
      },
      {
        path: 'top-sale.html',
        loadComponent: () =>
          import('../products/components/products-all-categories-list/products-all-categories-list.component').then((m) => m.ProductsAllCategoriesListComponent),
      },

      {
        path: 'products-group-list.html',
        loadComponent: () =>
          import('../products/components/products-group-card-list/products-group-card-list.component').then((m) => m.ProductsGroupCardListComponent),
      },


      // {
      //   path: 'p',
      //   loadChildren: () =>
      //     import('../products/products.module').then((m) => m.ProductsModule),
      // },

      {
        path: 'cart.html',
        loadChildren: () => import("../cart/cart.module").then((m) => m.CartModule)
      },
      {
        path: 'account',
        loadChildren: () => import("../account/account.module").then((m) => m.AccountModule)
      },

      { path: 'gift-card.html', component: GiftCardComponent, pathMatch: 'full' },

      { path: 'faqs.html', component: FaqsComponent, pathMatch: 'full' },

      { path: 'help-and-support.html', component: HelpAndSupportComponent, pathMatch: 'full' },

      { path: 'page/:url', component: CmsPagesComponent, pathMatch: 'full' },


      {
        path: 'c/:mainCategorySlug',
        loadComponent: () =>
          import('../products/components/product-switch/product-switch.component').then((m) => m.ProductSwitchComponent),
      },

      {
        path: 'c/:mainCategorySlug/:subCategorySlug',
        loadComponent: () =>
          import('../products/components/products-sub-categories-list/products-sub-categories-list.component').then((m) => m.ProductsSubCategoriesListComponent),
      },

      // product details with no category level
      {
        path: 'p/:urlKey',
        loadComponent: () =>
          import('../products/components/products-details-shared/products-details-shared.component').then((m) => m.ProductsDetailsSharedComponent),
      },

      // one level categories
      {
        path: 'p/:category/:urlKey',
        loadComponent: () =>
          import('../products/components/products-details-shared/products-details-shared.component').then((m) => m.ProductsDetailsSharedComponent),
      },
      // two level categories
      {
        path: 'p/:category/:subCategory/:urlKey',
        loadComponent: () =>
          import('../products/components/products-details-shared/products-details-shared.component').then((m) => m.ProductsDetailsSharedComponent),
      },
      // three level categories
      {
        path: 'p/:category/:subCategory/:subSubCate/:urlKey',
        loadComponent: () =>
          import('../products/components/products-details-shared/products-details-shared.component').then((m) => m.ProductsDetailsSharedComponent),
      },

      {
        path: 'c/:mainCategorySlug/:subCategorySlug/:SubSubCategorySlug',
        loadComponent: () =>
          import('../products/components/product-sub-sub-category/product-sub-sub-category.component').then((m) => m.ProductSubSubCategoryComponent),
      },



    ],
  },

];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class MainLayoutRoutingModule { }
