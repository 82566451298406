import { DOCUMENT, isPlatformBrowser } from '@angular/common';
import {
  AfterViewChecked,
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  Inject,
  OnInit,
  PLATFORM_ID,
  TemplateRef,
  ViewChild,
  inject,
} from '@angular/core';
import { ReCaptchaV3Service } from 'ng-recaptcha';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { Subscription, combineLatest } from 'rxjs';
import { AuthService } from 'src/app/core/services/auth.service';
import { SaveActionService } from 'src/app/core/services/save-action.service';

@Component({
  selector: 'app-auth',
  templateUrl: './auth.component.html',
  styleUrls: ['./auth.component.scss'],
})
export class AuthComponent implements OnInit, AfterViewInit ,AfterViewChecked {
  modalRef?: BsModalRef;
  config = {
    class: 'auth-dialog modal-dialog-centered ',
  };
  // forms "signIn" , "signUp" , "verifyForm"
  currentForm: any = 'signIn';
  currentDataForm : any = "";
  @ViewChild('authPopup', { read: TemplateRef }) authPopup!: TemplateRef<any>;
  platformId: Object = inject(PLATFORM_ID);

  constructor(
    private modalService: BsModalService,
    private _auth: AuthService,
    private saveAction: SaveActionService,
    private changeDetection: ChangeDetectorRef,
    @Inject(DOCUMENT) private document: Document
  ) {}


  ngOnInit(): void {

  }

  ngAfterViewInit(): void {
    this._auth.authPopup.subscribe((data) => {
      if (data) {
        // set previous form before assign it
        let previous = this.currentForm;
        this.currentDataForm = data;
        setTimeout(() => {
          this._auth.previousFormStream.next(previous);
        }, 100);
        this.currentForm = data.form;
        if(isPlatformBrowser(this.platformId)){
          data.isOpen ? this.openModal() : this.closeModal();
        }
      }
    });
  }

  ngAfterViewChecked(): void {
    if(this.modalRef && this.currentDataForm?.form =="signUp" && this.currentDataForm?.isOpen){
      this.viewRecaptcha();
    }
    else{
      this.hideRecaptcha();
    }
  }

  viewRecaptcha(){
    let element1 = this.document.getElementsByClassName('grecaptcha-badge');
    element1[0]?.parentElement?.setAttribute('style', 'visibility: visible; position: relative; z-index: 99999999');
    let element = this.document.getElementsByClassName('grecaptcha-badge');
    element[0]?.setAttribute(
      'style',
      `
    width: 256px; height: 60px; display: block; transition: right 0.3s ease 0s; position: fixed; bottom: 14px; right: -190px; box-shadow: gray 0px 0px 5px; border-radius: 2px; overflow: hidden;
    `
    );
  }

  hideRecaptcha(){
    if(this.document.getElementsByClassName('grecaptcha-badge')){
      let element = this.document.getElementsByClassName('grecaptcha-badge');
      element[0]?.parentElement?.setAttribute('style', 'display: none;');
      let element1:any = this.document.getElementsByClassName('grecaptcha-badge');
      if (element1[0]) {
        element1[0].style.display = 'none';
      }
    }
  }
  
  subscriptions: Subscription = new Subscription();
  openModal() {
    if (!this.modalRef) {
      const _combine = combineLatest([
        this.modalService.onHide,
        this.modalService.onHidden
      ]).subscribe(() => this.changeDetection.markForCheck());
      this.subscriptions.add(
        this.modalService.onHidden.subscribe((reason: string | any) => {
          this.modalRef = undefined;
        })
      );
      this.subscriptions.add(_combine);
      this.modalRef = this.modalService.show(this.authPopup, this.config);
    }
  }
  unsubscribe() {
    this.subscriptions.unsubscribe();
  }
  closePopup(data: any){
    this.closeModal()
  }
  closeModal() {
    this.modalRef?.hide();
    this.saveAction.deleteAction()
    let element = this.document.getElementsByClassName('grecaptcha-badge');
    element[0]?.parentElement?.setAttribute('style', 'display: none;');
    let element1:any = this.document.getElementsByClassName('grecaptcha-badge');
    if (element1[0]) {
      element1[0].style.display = 'none';
    }
  }
}
