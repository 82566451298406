import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpErrorResponse,
  HttpResponse,
} from '@angular/common/http';
import { Observable, tap } from 'rxjs';
import { NotificationsService } from 'angular2-notifications';
import { Router } from '@angular/router';
import { AuthService } from '../services/auth.service';
import { CustomNotificationService } from '../services/custom-notification.service';

@Injectable()
export class ErrorCodesInterceptor implements HttpInterceptor {
  constructor(
    private router: Router,
    private notificationService: NotificationsService,
    private _auth: AuthService,
    private customNotification : CustomNotificationService
  ) { }

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    return next.handle(request).pipe(
      tap(
        (res) => {

        },
        (err) => {
          // console.log("err: " , err?.error?.error);

          if (err instanceof HttpErrorResponse) {

            if (err?.status == 401) {//
              this.customNotification.showNotification({type:'lg-error' , message : err?.error?.error});
              this._auth.signOut();
              this.router.navigate(['/home.html']);
           }


          }
        }
      )
    );
  }
}
