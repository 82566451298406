<div class="container-navbar-scrolled" [ngStyle]="navbarBg">
  <div class="top-nav">
    <!-- first section -->
    <div class="d-flex f-section">
      <a class="logo-site" routerLink="/">
        <img src="./assets/images/logo-svg.svg" i18n-alt="@@logo" alt="logo" class="logo" />
      </a>
      <div class="d-flex w-100 align-items-center gap-3">
        <div class="search-bar" (click)="$event.stopPropagation()">
          <div [class]="locale === 'ar' ? 'search' : 'search-english'" class="position-relative p-0">
            <input
            id="nav-search"
            type="search"
            enterkeyhint="search"
            i18n-placeholder="@@navSearchSentence"
            placeholder="What are you looking for ?"
            [(ngModel)]="searchNav.search"

            (keyup.enter)="searchNav.showResultPopUp = false;overLayer.style.display='none';searchNav.routeWithSearchQueryParam()"
            (input)="suggestProducts()"
            (click)="openSearchResults()"
          />
          <div
            class="icon-search"
            [routerLink]="searchNav.search ? '/all-categories.html' : null"
            [queryParams]="{search:searchNav.search}"
            (click)="searchNav.routeWithSearchQueryParam();$event.cancelBubble = true;closeSearchResults()"
          >
            <img
              src="./assets/images/search.svg"
              i18n-alt="@@search"
              alt="search"
              *ngIf="!searchNav.searching"
            />
            <i *ngIf="searchNav.searching" class="fa-spin fas fa-spinner mt-1"></i>
          </div>
          </div>
          <div #resultList [hidden]="!searchNav.showResultPopUp" class="autocomplete-results" [ngStyle]="{'border': searchNav.searchResults.length > 0 ? '0.5px solid rgba(151, 151, 151, 0.37)':'none'}">
            <div *ngIf="searchNav.searchResults.length > 0 && !searchNav.searching" class="results-found">
              <ul class="result-list">
                <ng-container *ngFor="let result of searchNav.searchResults;let l = last">
                  <li>
                    <a class="d-flex align-items-center" (click)="goToProduct(result)"
                    [routerLink]="['/p/' + (result?.category|productDetailsPath) ,result?.url_key + '.html' ]">
                      <img class="prod-img" [src]="result?.base_image?.small_image_url" />
                      <div>
                        <p>{{ result?.name }}</p>
                        <span  class="current-price">{{ currentPrice(result) }}
                          <span class="currency" i18n="@@SAR">SAR</span>
                        </span>
                      <span  class="original-price mx-2" *ngIf="originalPrice(result)"> {{ originalPrice(result) }}
                          <span class="currency" i18n="@@SAR">SAR</span>
                      </span>
                      </div>
                    </a>
                  </li>
                  <!-- <ng-container *ngIf="l">
                  </ng-container> -->
                </ng-container>
              </ul>
            </div>
            <div [hidden]="!(searchNav.metaDataSearchResult?.total > searchNav.metaDataSearchResult?.per_page) || searchNav.searchResults.length == 0" (click)="searchNav.showResultPopUp=false;overLayer.style.display='none';searchNav.routeWithSearchQueryParam()" class="footer-result">
              <span i18n="@@showAllResults">Show All Results</span>
              <img src="./assets/icons/arrow-dropdown.svg" alt="">
            </div>
            <div *ngIf="searchNav.noResults && !searchNav.searching" class="autocomplete-results no-results">
              <div class="result-list">
                <p class="no-result-word" i18n="@@emptyMsg">No results found</p>
              </div>
            </div>
            <div *ngIf="searchNav.searchError && !searchNav.searching" class="autocomplete-results results-error">
              <div class="result-list">
                <p class="no-result-word text-danger text-break" >
                  <span i18n="@@serverErrorMessage">{{searchNav.searchError}}</span></p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- second section -->

    <!-- list categories section -->
    <div class="categories-nav" *ngIf="!hideCategoriesSection && !error">
      <div class="main-categories">
        <!-- all categories -->
        <div
          *ngIf="!isLoadingCategories"
          (mouseenter)="mouseEnter()"
          (mouseleave)="mouseLeave()"
          routerLink="/all-categories.html"
          [queryParams]="{}"
          class="all-categories"
          i18n="@@allCategories"
        >
          All Categories
        </div>
        <!-- all categories menu -->
        <!-- sub categories popup -->
        <div
          *ngIf="!isLoadingCategories"
          class="categories-layout"
          #categoriesLayout
          (mouseenter)="mouseEnter()"
          (mouseleave)="mouseLeave()"
        >
          <div class="close-icon" (click)="closeCategoriesPopup()">
            <i class="fa-regular fa-rectangle-xmark"></i>
          </div>
          <div class="main-list">
            <ul class="nav">
              <li
                *ngFor="let mainCate of mainCateName"
                (mouseenter)="
                  changeCategory(mainCate); isAllCategoriesHovered = mainCate
                "
                [ngClass]="{ active: mainCate == isAllCategoriesHovered }"
                class="nav-item"
                (click)="currentCategorySelected = mainCate; closeCategoriesPopup()"
                routerLinkActive="active"
                [routerLink]="
                  mainCategories[mainCate].sub_categories?.length ?
                    '/c/' + mainCategories[mainCate].slug + '.html' :
                    '/c/main/sub_category/' + mainCategories[mainCate].slug + '.html'
                "
              >
                {{ mainCate }}
              </li>
            </ul>
          </div>
          <div class="sub-categories-list">
            <div
              *ngFor="
                let subCate of mainCategories[this.currentCategorySelected]
                  ?.sub_categories;
                let indx = index
              "
              class="sub-menu"
            >
              <h3
                (click)="closeCategoriesPopup()"
                [routerLink]="
                  subCate?.sub_categories?.length ?
                  '/c/' + mainCategories[this.currentCategorySelected]?.slug + '/' + subCate.slug + '.html'
                  : '/c/' + mainCategories[this.currentCategorySelected]?.slug + '/sub_category/' + subCate.slug + '.html'
                "
                routerLinkActive="active"
                class="sub-title"
              >
                {{ subCate?.name }}
              </h3>
              <ul class="nav">
                <li
                  (click)="closeCategoriesPopup()"
                  *ngFor="let cate of subCate?.sub_categories; let i = index"
                  [routerLink]="
                    '/c/' +
                    mainCategories[this.currentCategorySelected]?.slug +
                    '/' +
                    subCate?.slug +
                    '/' +
                    cate?.slug + '.html'
                  "
                  routerLinkActive="active"
                  class="nav-item"
                >
                  {{ cate?.name }}
                </li>
              </ul>
            </div>
          </div>
        </div>
        <!-- end  all categories menu -->

        <div
          *ngIf="isLoadingCategories"
          class="all-categories"
        >
          <ngx-skeleton-loader
          [theme]="{ width: '110px', height: '40px', 'margin-bottom': '0px' , 'border-radius':'19px' }"
            appearance="line"
            class="mx-2 my-0"
          ></ngx-skeleton-loader>
        </div>
        <div
          *ngIf="!isLoadingCategories"
          class="list-main-categories"
        >
          <div
            *ngFor="let mainCate of categories?.slice(0,3)"
            (mousemove)="linkHovered(mainCate?.name)"
            (mouseleave)="linkLeave()"
            class="li-category"
            >
            <span
              (click)="closeCategoriesPopup()"
              [class]="mainCate?.name"
              [routerLink]="mainCate?.slug!=='best-sellers' ? mainCate.sub_categories?.length ?
                            '/c/' + mainCate?.slug + '.html'
                            : '/c/main/sub_category/' + mainCate?.slug + '.html'

                            : '/top-sale.html'
                            "
              class="cate"
              [id]="'trigger-' + mainCate?.name"
              routerLinkActive="active"
            >
              {{ mainCate?.name }}
            </span>

            <div
              *ngIf="mainCate?.sub_categories.length > 0"
              class="sub-categories"
              [ngClass]="{
                show: hoveredCategory == mainCate?.name,
                'w-300': mainCate?.sub_categories?.length == 1
              }"
              [id]="mainCate?.name"
            >
              <div class="child" *ngFor="let subCate of mainCate?.sub_categories">
                <span
                  class="sub-categories-name"
                  (click)="closeCategoriesPopup()"
                  [routerLink]=" subCate?.sub_categories?.length ?
                    '/c/' + mainCate?.slug + '/' + subCate.slug + '.html'
                    : '/c/' + mainCate?.slug + '/sub_category/' + subCate.slug + '.html'
                  "
                  routerLinkActive="active"
                  >{{ subCate.name }}</span
                >
                <ul class="sub-sub-categories">
                  <li
                    *ngFor="let subsubCate of subCate?.sub_categories"
                    (click)="closeCategoriesPopup()"
                    [routerLink]="
                      '/c/' +
                      mainCate.slug +
                      '/' +
                      subCate?.slug +
                      '/' +
                      subsubCate?.slug + '.html'
                    "
                    routerLinkActive="active"
                  >
                    {{ subsubCate.name }}
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div class="li-category">
            <span
            (click)="closeCategoriesPopup()"
            [routerLink]="'/top-sale.html'"
            class="cate best-sellers"
            [id]="'trigger-best-sellers'"
            routerLinkActive="active"
            i18n="@@specialOffers"
          >
            Special Offers
          </span>
          </div>
        </div>
        <div
          *ngIf="isLoadingCategories"
          class="list-main-categories"
        >
          <ngx-skeleton-loader
            *ngFor="let load of loadingArray"
            [theme]="{ width: '90px', height: '40px', 'margin-bottom': '0px' , 'border-radius':'19px' }"
            appearance="line"
            class="mx-2 my-0"
          ></ngx-skeleton-loader>
        </div>
      </div>


    </div>

    <!-- third section -->
    <div class="d-flex l-section" [ngClass]="{'l-section-english':locale==='en'}">
      <a class="carts hovering-style position-relative" routerLink="/cart.html">
        <img
         class="cart-image"
          src="./assets/icons/navbar/navbar-cart-icon.svg"
          i18n-alt="@@cart"
          alt="cart2"
        />
        <div class="position-absolute cart-count" *ngIf="cartCountItems">{{cartCountItems}}</div>
        <span i18n="@@nav-cart" [ngClass]="{'cart-label':locale === 'ar'}">Cart</span>
      </a>
      <div *ngIf="!isAuth" (click)="openAuthPopup()" class="login-widget hovering-style"
      (mouseover)="imgSrc = './assets/images/login-icon-color.svg'"
      (mouseout)="imgSrc = './assets/images/login-icon.svg'">
        <img [src]="imgSrc" alt="" />
        <span i18n="@@login" class="">Login</span>
      </div>
      <div *ngIf="isAuth" class="login-widget">
        <a
          class="nav-link dropdown-toggle"
          role="button"
          data-bs-toggle="dropdown"
          aria-expanded="false"
        >
          <!-- <span></span> -->
          <img class="img-profile" src="./assets/icons/navbar/profile-icon.svg" />
          {{ userName }}
          <img class="img-arrow" src="./assets/icons/navbar/account-arrow.svg" />
        </a>
        <ul class="dropdown-menu">
          <li>
            <a
              class="dropdown-item"
              routerLink="/account/orders"
              routerLinkActive="active"
            >
              <img src="./assets/images/orders.svg" alt="" />
              <span i18n="@@orders">Orders</span>
            </a>
          </li>
          <li>
            <a
              class="dropdown-item"
              routerLink="/account/favorites.html"
              routerLinkActive="active"
            >
              <img src="./assets/images/fav.svg" alt="" />
              <span i18n="@@favorites">Favorites</span>
            </a>
          </li>
          <li>
            <a
              class="dropdown-item"
              routerLink="/account/addresses"
              routerLinkActive="active"
            >
              <img src="./assets/images/addresses.svg" alt="" />
              <span i18n="@@addresses">Addresses</span>
            </a>
          </li>
          <li>
            <a
              class="dropdown-item"
              routerLink="/account/payments"
              routerLinkActive="active"
            >
              <img src="./assets/images/card-payment.svg" alt="" />
              <span i18n="@@payments">Payments</span>
            </a>
          </li>
          <li>
            <a
              class="dropdown-item"
              routerLink="/account/my-account.html"
              routerLinkActive="active"
            >
              <img src="./assets/images/user.svg" alt="" />
              <span i18n="@@personalProfile">Personal Profile</span>
            </a>
          </li>
          <li>
            <a class="dropdown-item last-item">
              <div
                class="d-flex align-items-center justify-content-between gap-3 w-100"
              >
                <div
                  class="d-flex gap-2 help"
                  routerLink="/help-and-support.html"
                  routerLinkActive="active"
                >
                  <img src="./assets/images/helpers.svg" alt="" />
                  <span i18n="@@help">Help</span>
                </div>
                <div (click)="logout()" class="d-flex gap-2">
                  <img src="./assets/images/logout.svg" alt="" />
                  <span i18n="@@logout">Logout</span>
                </div>
              </div>
            </a>
          </li>
        </ul>
      </div>
      <div class="switch-langs" dir="ltr" (click)="switchLanguage()">
        <span class="lang-name">{{ locale == 'ar' ? 'EN':'AR' }}</span>
        <img
          [src]="
            locale == 'ar'
              ? './assets/images/en-us.png'
              : './assets/images/ar-SA.svg'
          "
          i18n-alt="@@flag"
          alt="flag"
        />
      </div>
    </div>
  </div>

</div>

<div #overLayer (click)="searchNav.showResultPopUp=false;overLayer.style.display='none'" class="over-layer d-none"></div>
