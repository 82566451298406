import { AfterViewInit, Component, Inject, LOCALE_ID, OnDestroy, OnInit, Output, EventEmitter } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { NotificationsService } from 'angular2-notifications';
import { ReCaptchaV3Service } from 'ng-recaptcha';
import { BehaviorSubject } from 'rxjs';
import { AuthService } from 'src/app/core/services/auth.service';
import { CustomNotificationService } from 'src/app/core/services/custom-notification.service';
import { ErrorHandlingService } from 'src/app/core/services/error-handling.service';
import { HttpService } from 'src/app/core/services/http.service';
import { ValidationService } from 'src/app/core/services/validation.service';
import 'firebase/auth';
import 'firebase/firestore';
import firebase from 'firebase/compat/app';
import { environment } from 'src/environments/environment';
import { DOCUMENT } from '@angular/common';
import { MaskApplierService } from 'ngx-mask';

@Component({
  selector: 'app-sign-up',
  templateUrl: './sign-up.component.html',
  styleUrls: ['./sign-up.component.scss'],
})
export class SignUpComponent implements OnInit, AfterViewInit, OnDestroy {
  @Output() closeOutput = new EventEmitter();

  signUpForm!: FormGroup;
  loadingSubmit: boolean = false;
  reCAPTCHAToken: string = '';
  recaptchaValidation: BehaviorSubject<any> = new BehaviorSubject<any>(false);
  // OTP sent successfully. 
  otpMessage:any = $localize`:@@otpMessage:Please check your phone for the code.`;
  firebaseGeneralError:any = $localize`:@@firebaseGeneralError:An error occurred. Please try again later.`;
  firebaseInvalidPhoneNumber: any = $localize`:@@firebaseInvalidPhoneNumber:Invalid-phone-number.`;
  reCaptchaVerifier : any = null;
  constructor(
    private validateService: ValidationService,
    private _auth: AuthService,
    private _api: HttpService,
    private _errorHandling:ErrorHandlingService,
    private notifications: CustomNotificationService,
    private recaptchaV3Service: ReCaptchaV3Service,
    @Inject(DOCUMENT) private document: Document,
    @Inject(LOCALE_ID) public lang: string,
    private maskApplier : MaskApplierService,

  ) {}

  closePopup(){
    this.closeOutput.emit("");
  }

  ngOnInit(): void {
    this.signUpForm = new FormGroup({
      name: new FormControl('', [
        Validators.required,
        Validators.pattern(this.validateService.alphaPattern),
        Validators.maxLength(26),
        this.validateService.checkWhitespace()
      ]),
      phone: new FormControl('', [
        Validators.required,
        this.validateService.validatePhoneNumber(),
        this.validateService.checkWhitespace(),
      ]),
    });
    this.recaptchaValidation.subscribe({
      next: (res) => {
        if (res) {
          this.register();
        }
      },
    });
    firebase.initializeApp(environment.firebaseConfig);

  }
  ngAfterViewInit(): void {
    let element1 = this.document.getElementsByClassName('grecaptcha-badge');
    element1[0]?.parentElement?.setAttribute('style', 'visibility: visible; position: relative; z-index: 99999999');
    let element = this.document.getElementsByClassName('grecaptcha-badge');
    element[0]?.setAttribute(
      'style',
      `
    width: 256px; height: 60px; display: block; transition: right 0.3s ease 0s; position: fixed; bottom: 14px; right: -190px; box-shadow: gray 0px 0px 5px; border-radius: 2px; overflow: hidden;
    `
    );
  }
  openSignInPopup() {
    this._auth.authPopup.next({ isOpen: true, form: 'signIn' });
  }
  send() {
    if (this.signUpForm.valid && this.signUpForm.dirty) {
      this.recaptchaV3Service
        .execute('importantAction')
        .subscribe((token: string) => {
          this.reCAPTCHAToken = `${token}`;
          this.recaptchaValidation.next(true);
        });
    } else {
      this.signUpForm.markAllAsTouched();
    }
  }

  register() {
    if (this.reCAPTCHAToken) {
      this.loadingSubmit = true;
      let phone:string = this.signUpForm.value.phone;
      let payload = {
        name: this.signUpForm.value.name,
        // phone: (this.signUpForm.value.phone),
        phone: ('+966' + ( phone.startsWith('0') ? phone.substring(1) : phone )).replaceAll(' ', ''),
        'g-recaptcha-response': this.reCAPTCHAToken,
      };
      this._api.postReq('/customer/register', payload).subscribe({
        next: (res) => {
          this.successCallBack(res);
        },
        error: (err) => {
          this.errCallback(err);
        },
      });
    }
  }

  successCallBack(res: any) {
    this.getOTP();
    //show success notification
    this.notifications.showNotification({
      type: 'success',
      message: res?.message,
    });

  }
  errCallback(err: any) {
    if(err?.error?.message === "رقم الهاتف مسجل مسبقاً." || err?.error?.message === "The phone has already been taken."){
      this._auth.authPopup.next({ isOpen: true, form: 'signIn' });
    }
    this._errorHandling.errorHandling(err);
    //close loader
    this.loadingSubmit = false;
  }

  ngOnDestroy(): void {
    this.recaptchaValidation.unsubscribe();
    let element = this.document.getElementsByClassName('grecaptcha-badge');
    element[0]?.parentElement?.setAttribute('style', 'visibility: hidden;');
  }

  initializeRecaptcha() {
    if (!this.reCaptchaVerifier) {
      this.reCaptchaVerifier = new firebase.auth.RecaptchaVerifier(
        'recaptcha-container',
        {
          size: 'invisible',
        }
      );
    }
  }

  getOTP() {
    this.initializeRecaptcha();
    let errorMessage: string;
    let phone:string = this.signUpForm.value.phone;
    // console.log("this.signUpForm.value.phone",this.signUpForm.value.phone)
    phone = ('+966' + ( phone.startsWith('0') ? phone.substring(1) : phone )).replaceAll(' ', '')
    firebase
      .auth()
      .signInWithPhoneNumber(phone, this.reCaptchaVerifier)
      // .signInWithPhoneNumber(this.signUpForm.value.phone, this.reCaptchaVerifier)
      .then((confirmationResult) => {
        // console.log("confirmationResult: " , confirmationResult);
        //close loader
        this.loadingSubmit = false;
        // next step
        this.otpMessage
        this.notifications.showNotification({
          type: 'success',
          message: this.otpMessage,
          });
        this._auth.authPopup.next({ isOpen: true, form: 'verifyForm' });
        localStorage.setItem('phoneNumber', ( phone.startsWith('0') ? phone.substring(1) : phone ));
        localStorage.setItem(
          'verificationId',
          JSON.stringify(confirmationResult.verificationId)
        );
      })
      .catch((error) => {
        this.loadingSubmit = false;
        if (this.lang === 'ar') {
          if (error.message.includes('auth/invalid-phone-number')) {
            errorMessage = this.firebaseInvalidPhoneNumber;
          } else {
            errorMessage = this.firebaseGeneralError;
          }
        } else {
          errorMessage = error.message;
        }
        this.notifications.showNotification({
          type: 'lg-error',
          message: errorMessage,
        });
      });
  }


  getMask(): string {
    const phoneNumber = this.signUpForm.get('phone')?.value;
    let phoneNumberLength = phoneNumber.length ;
    if (phoneNumber && phoneNumber.startsWith('05')&& phoneNumberLength === 12) {
      return '000 000 0000'; 
    } else if (phoneNumber && phoneNumber.startsWith("5")&& phoneNumberLength === 11) {
      return '00 000 0000'; 
    } 
    else {
      return '00 000 0000';
    }
  }

  applyMasking(phoneNumber:string){
    let phoneNumberLength = phoneNumber.length ;
    if(phoneNumber && phoneNumber.startsWith("05") && phoneNumberLength === 12){
      this.signUpForm.controls['phone'].setValue(this.maskApplier.applyMask(phoneNumber, '000 000 0000'));
    }
    else if(phoneNumber.startsWith("5") && phoneNumberLength === 11){
      this.signUpForm.controls['phone'].setValue(this.maskApplier.applyMask(phoneNumber, '00 000 0000'));
    }
    else{
      this.signUpForm.controls['phone'].setValue(this.maskApplier.applyMask(phoneNumber, '00 000 0000'));
    }
  }
}

