import { Injectable } from '@angular/core';
import { HttpService } from './http.service';
import { BehaviorSubject, Subject } from 'rxjs';
import { FavoriteItem } from 'src/app/modules/account/models/interfaces/favorite-list';
import { OutputDto } from 'src/app/shared/models/interfaces/output-dto';
import { AuthService } from './auth.service';
import { FilterApplied, Product } from 'src/app/shared/models/interfaces/product';
import { AccountService } from 'src/app/modules/account/services/account.service';

@Injectable({
  providedIn: 'root'
})
export class ProductsService {

  // $wishlistSubscription: Subject<{ resp?: FavoriteItem[] | undefined,productId?:number,isLoading: boolean, errorMsg: string, successMsg: string , errorCode? : string }> = new Subject<any>();
  $productsFiltersSubscription : Subject<FilterApplied[]> = new Subject<any>()

  defaultProduct : Product | undefined;
  defaultBundleProduct : any;
  productsFilter : { id:number , code:string }[] = []
  constructor(private httpService: HttpService,
     private authService: AuthService,
     private accountService:AccountService,
     private _api : HttpService) { }
  // addRemoveFromWishlist(productId: number|undefined) {
  //   const AuthToken = this.authService.getUserToken();
  //   if (AuthToken) {
  //     this.httpService.postReq(`/customer/wishlist/${productId}`).subscribe({
  //       next: (resp: OutputDto<FavoriteItem[]>) => {
  //         this.accountService.updateFavorites(resp?.data || [])
  //         this.$wishlistSubscription.next({ resp: resp?.data, productId:productId, isLoading: false, errorMsg: "", successMsg: resp?.message || "" });
  //       }, error: error => {
  //         this.$wishlistSubscription.next({ isLoading: false, errorMsg: error?.message, successMsg: "" ,errorCode : error?.status });
  //       }
  //     })
  //   }
  //   else {
  //     this.authService.authPopup.next({ isOpen: true, form: 'signIn' });
  //   }
  // }

  getDefaultProduct(){
    return
    this._api.getReq('/products').subscribe({
      next: (res) => {
        this.defaultProduct = res?.data[0];
      },
      error: (err) => {
      },
    });
  }
  // getDefaultBundleProduct(){
  //   this._api.getReq('/products/types/grouped').subscribe({
  //     next: (res) => {
  //       console.log("res" , res);

  //       let defaultBundleProducts : any[] = res?.data?.products.map((product: any) => {
  //         return {
  //           name: product.name,
  //           url_key : product?.url_key,
  //           piecesNumber: product.bundle_options.total_no_of_products,
  //           price: parseFloat(product.price).toFixed(2),
  //           options: product.bundle_options.options.map((option: any) => {
  //             const firstProductInOption = option.products[0];
  //             return {
  //               base_image: firstProductInOption?.images[0]?.medium_image_url,
  //               description: firstProductInOption.name,
  //               cardBackground: '#FCDEEB',
  //             };
  //           }),
  //         };
  //       });
  //       this.defaultBundleProduct = defaultBundleProducts[0];
  //     },
  //     error: (err) => {
  //     },
  //   });
  // }
}
