<footer>
  <div class="container-fluid" *ngIf="!accountPage">
    <div class="info">
      <div class="logo">
        <figure class="logo-site">
          <img src="./assets/images/logo-svg.svg" class="pointer" i18n-alt="@@logo" alt="logo" routerLink="/" />
        </figure>
      </div>
      <ng-container *ngIf="!isFooterLoading">
        <div class="column footer-links">
          <ul>
            <li i18n="@@ourLatest">Our Latest</li>
            <!-- <li (click)="navigate()" i18n="@giftCard">Gift Card</li> -->
            <li
            *ngFor="let data of footerData?.column_5|sort:'sort_order'"
            [navigateUrl]="data?.url"
            >
              {{ data?.title }}
            </li>
          </ul>
        </div>
        <div class="column footer-links">
          <ul>
            <li i18n="@@account">My Account</li>
            <ng-container *ngFor="let data of footerData?.column_4|sort:'sort_order'">
              <li
              *ngIf="!data?.url?.includes('signIn') && !data?.url?.includes('signUp') && isAuth"
              [navigateUrl]="data?.url"
              >
                {{ data?.title }}
              </li>
              <li
              *ngIf="data?.url?.includes('signIn') && !isAuth"
              (click)="openAuthPopup('signIn')"
              >
                {{ data?.title }}
              </li>
              <li
              *ngIf="data?.url?.includes('signUp') && !isAuth"
              (click)="openAuthPopup('signUp')"
              >
                {{ data?.title }}
              </li>
            </ng-container>
          </ul>
        </div>
        <div class="column footer-links">
          <ul>
            <li i18n="@@sections">Categories</li>
            <li
            *ngFor="let data of footerData?.column_3|sort:'sort_order'"
            [navigateUrl]="data?.url"
            >
              {{ data?.title }}
            </li>
          </ul>
        </div>
        <div class="column footer-links">
          <ul>
            <li i18n="@@famousBrands">Famous Brands</li>
            <li
              *ngFor="let data of footerData?.column_2|sort:'sort_order'"
              [navigateUrl]="data?.url"
            >
              {{ data?.title }}
            </li>
          </ul>
        </div>
        <div class="column footer-links">
          <ul>
            <li i18n="@@sitePages">Site Pages</li>
            <li
            *ngFor="let data of footerData?.column_1|sort:'sort_order'"
            [navigateUrl]="data?.url"
            >
              {{ data?.title }}
            </li>
          </ul>
        </div>
      </ng-container>
      <!-- footer links as accodrion in resposnive -->
      <ng-container *ngIf="!isFooterLoading">
        <accordion [isAnimated]="true" class="resposnive-footer">
          <accordion-group #ourLatest>
            <div accordion-heading>
              <button class="btn btn-link btn-block justify-content-between d-flex w-100 shadow-none p-0" type="button" style="text-decoration: none;">
                <div class="pull-left float-left heading" i18n="@@ourLatest">Our Latest</div>
                <button type="button" class="btn btn-link float-right">
                  <img [hidden]="!ourLatest.isOpen" src="./assets/images/minus.svg" alt="plus icon">
                  <img [hidden]="ourLatest.isOpen" src="./assets/images/plus.svg" alt="plus icon">
                </button>
              </button>
            </div>
            <div class="column footer-responsive-links">
              <ul>
                <!-- <li i18n="@@ourLatest">Our Latest</li> -->
                <li *ngFor="let data of footerData?.column_5|sort:'sort_order'"
                [navigateUrl]="data?.url">
                  {{ data?.title }}
                </li>
              </ul>
            </div>
          </accordion-group>


          <accordion-group #myAccount>
            <div accordion-heading>
              <button class="btn btn-link btn-block justify-content-between d-flex w-100 shadow-none p-0" type="button" style="text-decoration: none;">
                <div class="pull-left float-left heading" i18n="@@account">My Account</div>
                <button type="button" class="btn btn-link float-right">
                  <img [hidden]="!myAccount.isOpen" src="./assets/images/minus.svg" alt="plus icon">
                  <img [hidden]="myAccount.isOpen" src="./assets/images/plus.svg" alt="plus icon">
                </button>

              </button>
            </div>
            <div class="column footer-responsive-links">
              <ul>
                <ng-container *ngFor="let data of footerData?.column_4|sort:'sort_order'">
                  <li *ngIf="data.title!='Sign In'&& data.title!='Sign Up' && data.title!='تسجيل الدخول' 
                  && data.title!='إنشاء حساب'" [navigateUrl]="data?.url">
                    {{ data?.title }}
                  </li>
                  <li *ngIf="(data.title=='Sign In' || data.title=='تسجيل الدخول') && !isAuth " (click)="openAuthPopup('signIn')">
                    {{ data?.title }}
                  </li>
                  <li *ngIf="(data.title=='Sign Up' || data.title=='إنشاء حساب') && !isAuth " (click)="openAuthPopup('signUp')">
                    {{ data?.title }}
                  </li>
                </ng-container>
              </ul>
            </div>
          </accordion-group>

          <accordion-group #mostCategories>
            <div accordion-heading>
              <button class="btn btn-link btn-block justify-content-between d-flex w-100 shadow-none p-0" type="button" style="text-decoration: none;">
                <div class="pull-left float-left heading" i18n="@@sections">Categories</div>
                <button type="button" class="btn btn-link float-right">
                  <img [hidden]="!mostCategories.isOpen" src="./assets/images/minus.svg" alt="plus icon">
                  <img [hidden]="mostCategories.isOpen" src="./assets/images/plus.svg" alt="plus icon">
                </button>
              </button>
            </div>
            <div class="column footer-responsive-links">
              <ul>
                <li *ngFor="let data of footerData?.column_3|sort:'sort_order'"
                [navigateUrl]="data?.url">
                  {{ data?.title }}
                </li>
              </ul>
            </div>
          </accordion-group>



          <accordion-group #famousBrands>
            <div accordion-heading>
              <button class="btn btn-link btn-block justify-content-between d-flex w-100 shadow-none p-0" type="button" style="text-decoration: none;">
                <div class="pull-left float-left heading" i18n="@@famousBrands">Famous Brands</div>
                <button type="button" class="btn btn-link float-right">
                  <img [hidden]="!famousBrands.isOpen" src="./assets/images/minus.svg" alt="plus icon">
                  <img [hidden]="famousBrands.isOpen" src="./assets/images/plus.svg" alt="plus icon">
                </button>
              </button>
            </div>
            <div class="column footer-responsive-links">
              <ul>
                <li *ngFor="let data of footerData?.column_2|sort:'sort_order'"
                [navigateUrl]="data?.url">
                  {{ data?.title }}
                </li>
              </ul>
            </div>
          </accordion-group>

          <accordion-group #sitePages>
            <div accordion-heading>
              <button class="btn btn-link btn-block justify-content-between d-flex w-100 shadow-none p-0" type="button" style="text-decoration: none;">
                <div class="pull-left float-left heading" i18n="@@sitePages">Site Pages</div>
                <button type="button" class="btn btn-link float-right">
                  <img [hidden]="!sitePages.isOpen" src="./assets/images/minus.svg" alt="plus icon">
                  <img [hidden]="sitePages.isOpen" src="./assets/images/plus.svg" alt="plus icon">
                </button>
              </button>
            </div>
            <div class="column footer-responsive-links last-link">
              <ul>
                <li *ngFor="let data of footerData?.column_1|sort:'sort_order'"
                [navigateUrl]="data?.url">
                  {{ data?.title }}
                </li>
              </ul>
            </div>

          </accordion-group>
          <div class="bottom"></div>
        </accordion>
      </ng-container>
      <!-- is loading -->
       <ng-container *ngIf="isFooterLoading">
         <div *ngFor="let schema of loadingSchema" class="column pt-2">
           <ngx-skeleton-loader
             count="5"
             [theme]="{
             'border-radius': '5px',
             width: '100px',
             display:'block',
             'margin-bottom':'20px',
           }"
           ></ngx-skeleton-loader>
         </div>
       </ng-container>
      <div class="column social_logo">
        <ul class="last-columns-logos">
          <li>
            <a [href]="contacts?.contacts?.twitter_account" target="_blank">
              <img src="./assets/images/twitter.svg" alt="twitter-logo" />
            </a>
          </li>
          <li>
            <a [href]="contacts?.contacts?.facebook_account" target="_blank">
              <img src="./assets/images/facebook.svg" alt="facebook-logo" />
            </a>
          </li>
          <li>
            <a [href]="contacts?.contacts?.snapchat_account" target="_blank">
              <img src="./assets/images/snapchat.svg" alt="snapchat-logo" />
            </a>
          </li>
          <li>
            <a [href]="contacts?.contacts?.tikTok_account" target="_blank">
              <img src="./assets/images/tiktok.svg" alt="tiktok-logo" />
            </a>
          </li>
          <li>
            <a [href]="contacts?.contacts?.instagram_account" target="_blank">
              <img src="./assets/images/instagram.svg" alt="instagram-logo" />
            </a>
          </li>
          <!-- <li>
            <img src="./assets/images/youtube.svg" alt="youtube-logo" />
          </li> -->
        </ul>

        <div class="group stores">
          <div class="logo">
            <a href="https://play.google.com/store/apps/details?id=com.mcit.imackup&pcampaignid=web_share" target="_blank" rel="noopener noreferrer">
              <img src="./assets/images/google-play.png" alt="google-play-logo" />
            </a>
          </div>
          <div class="logo">
            <a href="https://apps.apple.com/sa/app/i-makeup-%D8%A2%D9%8A-%D9%85%D9%8A%D9%83-%D8%A7%D8%A8/id1662317896" target="_blank" rel="noopener noreferrer">
              <img src="./assets/images/app-store.png" alt="app-store-logo" />
            </a>
          </div>
        </div>
      </div>
    </div>
    <div class="payment-logos">
      <div >
        <a href="https://www.mastercard.us/en-us.html" target="_blank">
          <img
            src="./assets/images/master-card-logo-svg.svg"
            alt="mastercard-logo"
          />
        </a>
      </div>

      <div class="applepay-logo">
        <a href="https://www.apple.com/apple-pay/" target="_blank">
          <img src="./assets/images/apple-pay-logo.svg" alt="applepay-logo" />
        </a>
      </div>

      <div class="stc-logo">
        <a href="https://stcpay.com.sa/" target="_blank">
          <img src="./assets/icons/footer/Stc_pay.svg" alt="stc-logo" />
        </a>
      </div>

      <div class="visa-logo">
        <a href="https://eg.visamiddleeast.com/ar_EG" target="_blank">
          <img src="./assets/images/visa-logo-svg.svg" alt="visa-logo" />
        </a>
      </div>

      <div class="mada-logo">
        <a href="https://www.mada.com.sa/en/homepage" target="_blank">
          <img src="./assets/images/mada-logo.svg" alt="mada-logo" />
        </a>
      </div>

      <div class="d-flex align-items-center">
        <a href="https://tabby.ai/" target="_blank">
          <img src="./assets/images/tabby.svg" alt="tappy-logo" />
        </a>
      </div>

      <div class="tamara-logo d-flex align-items-center">
        <a href="https://tamara.co/en-SA" target="_blank">
          <img src="./assets/images/tamara-svg.svg" alt="tamara-logo" />
        </a>
      </div>

    </div>
  </div>
  <app-small-footer
    [isContactLinksLoading]="isContactLinksLoading"
    [contacts]="contacts"
  ></app-small-footer>
</footer>
