import { Injectable } from '@angular/core';
import { BehaviorSubject, Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ShowFooterService {
  isLoading:BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false)
  constructor() { }
  setShowFooterState(){
    if(!this.isLoading.getValue())
      this.isLoading.next(true)
  }
}
