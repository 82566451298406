import { Component,  Inject, Input,  LOCALE_ID,  PLATFORM_ID,  inject } from '@angular/core';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { AddToCartPopupComponent } from '../add-to-cart-popup/add-to-cart-popup.component';
import { CustomNotificationService } from 'src/app/core/services/custom-notification.service';
import { AuthService } from 'src/app/core/services/auth.service';
import { Router, RouterModule } from '@angular/router';
import { Product } from '../../models/interfaces/product';
import { CartService } from '../../services/cart.service';
import { OutputDto } from '../../models/interfaces/output-dto';
import { ProductsService } from 'src/app/core/services/products.service';
import { Subscription } from 'rxjs';
import { AccountService } from 'src/app/modules/account/services/account.service';
import { FavoriteItem } from 'src/app/modules/account/models/interfaces/favorite-list';
import { CommonModule, DOCUMENT, getLocaleDirection, isPlatformServer } from '@angular/common';
import { ErrorHandlingService } from 'src/app/core/services/error-handling.service';
import { NgxSkeletonLoaderModule } from 'ngx-skeleton-loader';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ProductDetailsPathPipe } from '../../pipes/product-details-path.pipe';
import { SaveActionService } from 'src/app/core/services/save-action.service';

@Component({
  standalone:true,
  selector: 'app-product-card',
  templateUrl: './product-card.component.html',
  styleUrls: ['./product-card.component.scss'],
  imports:[
    CommonModule,
    NgxSkeletonLoaderModule,
    FormsModule,
    ReactiveFormsModule,
    RouterModule,
    ProductDetailsPathPipe
  ]
})
export class ProductCardComponent {
  @Input() product!: Product;
  @Input() isConfigurableProduct: boolean = false;
  // @Input() originalPriceColor: string = '#666668';
  @Input() cardBackground: string = '';
  // @Input() discountedPriceColor: string = '#D50102';
  @Input() cardWidth: string = '256px';
  @Input() productImageWidth: string = '234px';
  @Input() productImageheight: string = '232px';
  @Input() favoritesProducts: FavoriteItem[] = [];
  @Input() isLoading: boolean = false;
  @Input() isAddedToCart: boolean = false;
  @Input() fromProductsGroup : boolean = false;
  cartItemId : number = 0;

  notificationType: string = '';
  attributes:any[] = [];
  loadingAddToCart = false;

  loadingSchema:any = ['','','','','','','','']
  loadingWishlisting : boolean = false ;
  wishlistSubscription !: Subscription ;
  wishProductId : any;
  @Input() isDragged : boolean = false;
  static addCartPopup?: BsModalRef;
  wishlist : FavoriteItem[] = [];
  isInWishlist : boolean = false;
  isInCartItems : boolean = false;
  platform_id:Object = inject(PLATFORM_ID);
  originalPriceColor: string = '#000000';
  discountedPriceColor: string = '#808080';
  constructor(
    private router: Router,
    private cartService: CartService,
    private authService: AuthService,
    private modalService: BsModalService,
    private notifications: CustomNotificationService,
    private accountService : AccountService ,
    private _errorHandling:ErrorHandlingService,
    private productsService : ProductsService ,
    private customNotification : CustomNotificationService,
    private saveAction: SaveActionService,
    @Inject(DOCUMENT) private document: Document,
    @Inject(LOCALE_ID) public lang: string,
  ) {}
  localeDir !: string ;

  ngOnInit() {
    this.localeDir = getLocaleDirection(this.lang);
    this.modalService.onShow.subscribe(addPopup=>{
      ProductCardComponent.addCartPopup = addPopup;
    })
    // this.initializeConfigurableAttributes();
    // this.accountService.getFavorites();
    this.isInCartItems = this.isInCartItemsFunction(this.product?.id);
    this.accountService.cartListSubject$.subscribe((resp)=>{
      this.isInCartItems = this.isInCartItemsFunction(this.product?.id);
    })

  }

  ngAfterViewInit(): void {
    if(isPlatformServer(this.platform_id)){return}
    // this.document.documentElement.addEventListener(
    //   'click',
    //   this.hideIfIsBackdropClick.bind(this)
    // );

    if(document.body.clientWidth < 600){
      this.productImageWidth = '182px';
      this.productImageheight = '182px';
    }else if(document.body.clientWidth < 445){
      this.productImageWidth = '234px';
      this.productImageheight = '232px';
    }

  }
  

  addRemoveProductFromCart(product: Product , event : MouseEvent) {
    event.stopPropagation();
    event.preventDefault();
    // **** Open the comments when you want to apply the guards
    const AuthToken = this.authService.getUserToken();
    if (AuthToken){
      const filteredFavoritesProducts = this.favoritesProducts.filter((productFromFavorites:FavoriteItem)=>productFromFavorites?.product?.id !== product.id)
      if(product?.type == 'configurable'){
        let slug:string = product?.category?.length > 0 ? product?.category[0]?.slug : '';
        const slugsArr = slug.split("/");

        if(ProductCardComponent.addCartPopup){
          ProductCardComponent.addCartPopup?.hide();
          ProductCardComponent.addCartPopup = undefined;
        }
        this.notifications.showNotification({
          type: 'warning',
          message:$localize`:@@chooseSpecificProduct:Choose a specific product to add to your cart`,
        });
        this.router.navigate([`/p/${slug}/${product.url_key}.html`])
        return
      }
      if (!this.loadingAddToCart && !this.isInCartItems) {
        this.loadingAddToCart = true;
        this.cartService
          .addProductTocart(product?.id || 0, {
            product_id: product?.id,
            quantity: 1,
          })
          .subscribe(
            (res: OutputDto<any>) => {
              if (res.data) {
                this.isInCartItems = true;
                this.accountService.updateCartItemList(res?.data || [])
                this.accountService.updateCartItems.next(true); // when add/remove cart from cart page call api again
                this.cartService.selectedProduct = this.product;
                let itemInCart = res?.data?.items.filter((item:any)=> item?.product?.id == product?.id);
                if(itemInCart?.length > 0){
                  this.cartItemId = itemInCart[0]?.id;
                }
                // this.isAddedToCart = true;
                if (this.isInCartItems) {

                  if(ProductCardComponent.addCartPopup){
                    ProductCardComponent.addCartPopup?.hide();
                  }


                  ProductCardComponent.addCartPopup = this.modalService.show(
                    AddToCartPopupComponent,
                    Object.assign({}, { class: 'cart-add-modal', id: product.id })
                  );
                    // console.log(AddToCartPopupComponent)
                    // console.log(ProductCardComponent.addCartPopup)

                  // if(this.favoritesProducts?.length){
                  //   this.accountService.updateFavorites(filteredFavoritesProducts);
                  // }
                  this.notifications.showNotification({
                    type: 'add-cart',
                    message: res.message || '',
                  });
                }
              } else {
                this.notifications.showNotification({
                  type: 'lg-error',
                  message: res.message || '',
                });
              }
              this.loadingAddToCart = false;
            },
            (err) => {
              this._errorHandling.errorHandling(err);
              this.loadingAddToCart = false;
            }
          );
      }else if(!this.loadingAddToCart && this.isInCartItems){
        this.loadingAddToCart = true;
        this.cartService.removeProductToCart(this.cartItemId).subscribe(res=>{
          this.isInCartItems = false;
          this.accountService.updateCartItemList(res?.data || [])
          this.accountService.updateCartItems.next(true); // when add/remove cart from cart page call api again
          // this.isAddedToCart = false;
          this.loadingAddToCart = false;
          this.notifications.showNotification({
            type: 'remove-cart',
            message: res.message || '',
          });
        },err=>{
          this._errorHandling.errorHandling(err);
          this.loadingAddToCart = false;
        })
      }
    }else{
      this.saveAction.setPreviousAction({type:"add",product:product});
      this.authService.authPopup.next({ isOpen: true, form: 'signIn' });
     }
  }

  initializeConfigurableAttributes(){
    this.attributes = [];
    console.log(this.product?.super_attributes)
    if(this.product?.super_attributes){
      this.product?.super_attributes?.forEach((attr:any,index:number)=>{
          if(index > 1){return} // get only two attributes
          this.attributes.push({
            code:attr?.code,
            name:attr?.name,
            options:attr.options
          });
          // this.getAllAttrValues(attr?.code,index)
      });
    }
  }


  // getAllAttrValues(attr_code:string,index:number){
  //   let attrValues:any[] = [];
  //   let options:any[] = [];
  //   this.product?.configurable_attributes?.forEach((config_attr:any)=>{
  //     if(config_attr[`${attr_code}_name`]){

  //       // check if is matching true
  //       if(!attrValues.includes(config_attr[`${attr_code}_name`])){
  //         attrValues.push(config_attr[`${attr_code}_name`]);
  //         options.push({
  //           name: config_attr[`${attr_code}_name`],
  //           swatch_value:config_attr[`${attr_code}_swatch_value`],
  //         })
  //       }
  //     }
  //   })
  //   this.attributes[index]['options'] = options;
  // }


  isInCartItemsFunction(productId : any){
    let itemsCart:any[] = (this.accountService.cartItemList?.itemsIds || []).filter((item : any)=> item.product_id == productId);
    if(itemsCart?.length > 0){
      this.cartItemId = itemsCart[0].item_id;
      return true;
    }
    return false;
  }
}
