import { Component, Inject, LOCALE_ID, OnInit, Output, EventEmitter } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { AuthService } from 'src/app/core/services/auth.service';
import { ValidationService } from 'src/app/core/services/validation.service';
import firebase from 'firebase/compat/app';
import 'firebase/auth';
import 'firebase/firestore';
import { environment } from 'src/environments/environment';
import { CustomNotificationService } from 'src/app/core/services/custom-notification.service';
import { MaskApplierService } from 'ngx-mask';
@Component({
  selector: 'app-sign-in',
  templateUrl: './sign-in.component.html',
  styleUrls: ['./sign-in.component.scss'],
})
export class SignInComponent implements OnInit {

  @Output() closeOutput = new EventEmitter();

  reCaptchaVerifier: any = null;
  signInForm!: FormGroup;
  loadingSubmit: boolean = false;
  otpMessage: any = $localize`:@@otpMessage:OTP sent successfully. Please check your phone for the code.`;
  firebaseGeneralError: any = $localize`:@@firebaseGeneralError:An error occurred. Please try again later.`;
  firebaseInvalidPhoneNumber: any = $localize`:@@firebaseInvalidPhoneNumber:Invalid-phone-number.`;
  constructor(
    private validateService: ValidationService,
    private _auth: AuthService,
    private notifications: CustomNotificationService,
    @Inject(LOCALE_ID) public lang: string,
    private maskApplier : MaskApplierService,
  ) {}

  ngOnInit(): void {
    this.signInForm = new FormGroup({
      phone_number: new FormControl('', [
        Validators.required,
        this.validateService.validatePhoneNumber(),
        this.validateService.checkWhitespace(),
      ]),
    });
    firebase.initializeApp(environment.firebaseConfig);
  }
  
  close(){
    this.closeOutput.emit("");
  }

  send() {
    if (this.signInForm.valid && this.signInForm.dirty) {
      this.loadingSubmit = true;
      this.getOTP();
    } else {
      this.signInForm.markAllAsTouched();
    }
  }

  openSignUpPopup() {
    this._auth.authPopup.next({ isOpen: true, form: 'signUp' });
  }

  initializeRecaptcha() {
    if (!this.reCaptchaVerifier) {
      this.reCaptchaVerifier = new firebase.auth.RecaptchaVerifier(
        'recaptcha-container',
        {
          size: 'invisible',
        }
      );
    }
  }

  getOTP() {
    this.initializeRecaptcha();
    let errorMessage: string;
    let phone:string = this.signInForm.value.phone_number;
    firebase
      .auth()
      // .signInWithPhoneNumber(this.signInForm.value.phone_number, this.reCaptchaVerifier)
      .signInWithPhoneNumber(
        '+966' + ( phone.startsWith('0') ? phone.substring(1) : phone ),
        this.reCaptchaVerifier
      )
      .then((confirmationResult) => {
        // console.log("confirmationResult: " , confirmationResult);
        //close loader
        this.loadingSubmit = false;
        // next step
        this.notifications.showNotification({
          type: 'success',
          message: this.otpMessage,
        });
        this._auth.authPopup.next({ isOpen: true, form: 'verifyForm' });
        localStorage.setItem(
          'phoneNumber',
          '+966' + ( phone.startsWith('0') ? phone.substring(1) : phone )
        );
        localStorage.setItem(
          'verificationId',
          JSON.stringify(confirmationResult.verificationId)
        );
      })
      .catch((error) => {
        this.loadingSubmit = false;
        // console.log('error.message', error.message);
        if (this.lang === 'ar') {
          if (error.message.includes('auth/invalid-phone-number')) {
            errorMessage = this.firebaseInvalidPhoneNumber;
          } else {
            errorMessage = this.firebaseGeneralError;
          }
        } else {
          errorMessage = error.message;
        }
        this.notifications.showNotification({
          type: 'lg-error',
          message: errorMessage,
        });
      });
  }

  getMask(): string {
    const phoneNumber = this.signInForm.get('phone_number')?.value;
    let phoneNumberLength = phoneNumber.length ;
    if (phoneNumber && phoneNumber.startsWith('05')&& phoneNumberLength === 12) {
      return '000 000 0000'; 
    } else if (phoneNumber && phoneNumber.startsWith("5")&& phoneNumberLength === 11) {
      return '00 000 0000'; 
    } 
    else {
      return '00 000 0000';
    }
  }
  applyMasking(phoneNumber:string){
    let phoneNumberLength = phoneNumber.length ;
    if(phoneNumber && phoneNumber.startsWith("05")&& phoneNumberLength === 12){
      this.signInForm.controls['phone_number'].setValue(this.maskApplier.applyMask(phoneNumber, '000 000 0000'));
    }
    else if(phoneNumber.startsWith("5") && phoneNumberLength === 11){
      this.signInForm.controls['phone_number'].setValue(this.maskApplier.applyMask(phoneNumber, '00 000 0000'));
    }
    else{
      this.signInForm.controls['phone_number'].setValue(this.maskApplier.applyMask(phoneNumber, '00 000 0000'));
    }
  }

}
