<a 
[routerLink]="!isDragged ? ['/p/' + (product?.category|productDetailsPath) ,product?.url_key + '.html']  : null" 
[ngClass]=" fromProductsGroup? 'grouped-list-carousel' : null"
>
  <div *ngIf="!isLoading" class="product-card-container" [ngStyle]="{ width: cardWidth }">
    <div class="product-card px-2 pb-2" [ngStyle]="{
        background: cardBackground,
        'padding-top': '10px',
        'display':'flex',
        'flex-direction':'column'
      }">
      <div class="contain-height d-flex align-items-center"
        [ngClass]="product?.isNew ? 'justify-content-start' : 'justify-content-between'">
        <span *ngIf="product?.isNew" class="product-label label mb-2 pb-4" i18n="@@JustArrived">
          Just Arrived
        </span>
      </div>
      <img
        [ngStyle]="{
        width: productImageWidth,
        height: productImageheight,
        'margin-top': '10px'
      }" class="product-image" [src]="
        product?.base_image?.original_image_url ||
        product?.grouped_product_images?.original_image_url ||
        './assets/images/home/default-image.webp'
      " />
      <div class="position-relative" style="flex-grow: 1;">
        <div class="info">
          <!-- [ngStyle]="{ 'transform': product?.type === 'configurable' ? 'translateY(20px)' : 'none' }" -->
          <h5 >{{ product?.name }}</h5>
          <!-- [ngStyle]="{ 'transform': product?.type === 'configurable' ? 'translateY(15px)' : 'none' }" -->
          <div class="star-rating" >
            <div class="star">
              <img *ngFor="let star of [1, 2, 3, 4, 5]"
              [src]="star <=
              (product?.reviews?.average_rating || 0) ? './assets/images/stars-fill.svg' : './assets/images/stars-fill.svg'"
              [ngClass]="{'fill-star': star <=
              (product?.reviews?.average_rating || 0)}">
            </div>
            <span>( {{ product?.reviews?.total || 0 }} )</span>
          </div>

          <div class="below-section">
            <!-- [ngStyle]="{ 'margin-top': product.type != 'configurable' ? '30px' : '5px' }" -->
            <div class="price-container d-flex flex-wrap gap-1" style="margin-top:5px">
              <div class="d-flex justify-content-between">
                <div class="">
                  <ng-container *ngIf="localeDir === 'ltr'">
                    <span [ngStyle]="{ color: originalPriceColor }" class="original-price">
                        {{ product?.currentPrice?.priceFirstPart}},
                        <small>
                          {{product?.currentPrice?.priceSecondPart}}</small>
                      <sup [ngStyle]="{ color: originalPriceColor }" class="currency" i18n="@@SAR">SAR</sup>
                    </span>
                  </ng-container>
                  <ng-container *ngIf="localeDir === 'rtl'">
                    <span [ngStyle]="{ color: originalPriceColor }" class="original-price">
                      <small>
                        {{product?.currentPrice?.priceSecondPart}}</small>
                        ,{{ product?.currentPrice?.priceFirstPart}}
                      <sup [ngStyle]="{ color: originalPriceColor }" class="currency" i18n="@@SAR">SAR</sup>
                    </span>
                  </ng-container>
                </div>
                <div *ngIf="product?.special_price" class="d-flex justify-content-end">
                  <div>
                    <span [ngStyle]="{ color: discountedPriceColor }" class="discounted-price">{{
                      product?.price 
                      }}</span>
                  </div>
                </div>
              </div>
            </div>
            <!-- <div *ngIf="product?.type == 'configurable' && attributes.length>0" [ngStyle]="{ 'margin-top': '2px'}" class="attributes">
              <ng-container *ngFor="let attr of attributes">
                <div *ngIf="attr?.options && attr?.options?.length > 0 && attr?.options[0]?.swatch_value" class="product-colors-container">
                  <p  i18n="@@colors" >Colors</p>
                  <div class="circle-list">
                    <span *ngFor="let color of attr?.options?.length>6 ? attr?.options?.slice(0,6) : attr?.options" class="circle"
                      [style.background-image]="'url(' + color?.swatch_value?.replaceAll(' ','%20') + ')'"></span>
                    <span *ngIf="attr?.options?.length>6" class="number">+{{ attr?.options?.length - 6 }}</span>
                    <img *ngIf="attr?.options?.length>6" src="./assets/images/three-colors.svg" i18n-alt="@@colors"
                      alt="three-colors" />
                  </div>
                </div>

                <div class="product-volums" *ngIf="attr?.options && attr?.options?.length > 0 && !attr?.options[0]?.swatch_value">
                  <p *ngFor="let option of attr?.options?.length>4 ? attr?.options?.slice(0,4) : attr?.options" class="active">
                    {{ option?.name }}
                  </p>
                  <p *ngIf="attr?.options?.length>4" class="active">+{{ attr?.options?.length - 4 }}</p>
                </div>

              </ng-container>
            </div> -->

          </div>


        </div>
        <div class="add-to-cart"
        [class.added]="isInCartItems"
        (click)="addRemoveProductFromCart(product , $event)" [class.added]="isInCartItems">
          <img *ngIf="!loadingAddToCart" [src]="isInCartItems ? './assets/images/white-cart.svg' : './assets/images/pink-cart.svg'"  alt="cart-icon" i18n-alt="@@cart" />
          <i *ngIf="loadingAddToCart" class="fa-spin fas fa-spinner"></i>
          <span [class.white]="isInCartItems" i18n="@@add">Add</span>
        </div>
      </div>
    </div>
  </div>
</a>

<!-- isLoading -->
<div *ngIf="isLoading" class="product-card-container" [ngStyle]="{ width: cardWidth }">
  <div class="product-card w-100" [ngStyle]="{ width: cardWidth }" style="background: #fff;">
    <div class="d-block w-100">
      <ngx-skeleton-loader [theme]="{
      'border-radius': '10px',
      width:'100%',
      margin:'0px',
      height: '200px',
      }" />
    </div>
    <div class="position-relative">
      <div class="info">
        <h5>
          <ngx-skeleton-loader [theme]="{
          'border-radius': '10px',
          width:'90%',
          margin:'0px',
          height: '35px',
          }" />
        </h5>
        <div class="star-rating">
          <ngx-skeleton-loader [theme]="{
          'border-radius': '10px',
          width:'120px',
          margin:'0px',
          height: '15px',
          }" />
        </div>
        <div class="price-container mt-2">
          <ngx-skeleton-loader [theme]="{
          'border-radius': '10px',
          width:'80px',
          margin:'0px',
          height: '30px',
          }" />
        </div>
      </div>
    </div>
  </div>
</div>

<!-- example for using small product card component -->
<!-- <app-product-card
[product]="{
  imageUrl: './assets/images/Bitmap.png',
  description: 'Makeup sponge from Beauty Blender, Purple',
  rating: 5,
  originalPrice: 8.99,
  discountedPrice: 6.33,
  productColors: ['#603524', '#631F1D', '#51282E','#603524', '#631F1D', '#51282E'],
  productVolums:[240,400,500]
}"
[showProductColors]="false"
[isProductVolums]="false"
></app-product-card> -->
