import {
  Component,
  OnDestroy,
  OnInit,
  Inject,
  PLATFORM_ID,
  AfterViewChecked,
  AfterViewInit,
} from '@angular/core';
import { BackgroundColorService } from './core/services/background-color.service';
import { Subscription } from 'rxjs';
import { environment } from 'src/environments/environment';
import { AuthService } from './core/services/auth.service';
import { isPlatformBrowser, isPlatformServer } from '@angular/common';
import { CategoryService } from './core/services/category.service';
import { ProductsService } from './core/services/products.service';
import { ReCaptchaV3Service } from 'ng-recaptcha';
import { AccountService } from './modules/account/services/account.service';
import { FavoriteItem } from './modules/account/models/interfaces/favorite-list';
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit, OnDestroy{
  private subscription!: Subscription;
  hostUrl: any = environment.hostUrl;

  mainStyle: { 'background-color': string } = { 'background-color': '#FDFDFD' };

  constructor(
    private backgroundColor: BackgroundColorService,
    private authService: AuthService,
    private categoryService: CategoryService,
    @Inject(PLATFORM_ID) private platformId: any,
    @Inject('LOCALSTORAGE') private localStorage: any,
    private productsService : ProductsService,
    private recaptchaV3Service: ReCaptchaV3Service,
    private accountService : AccountService

  ) {}
  ngOnInit() {
    // this.productsService.getDefaultProduct();
    // this.productsService.getDefaultBundleProduct();
    this.subscription = this.backgroundColor.backgroundColor$.subscribe(
      (color) => {
        this.mainStyle = { 'background-color': color?.home };
      }
    );

    if(isPlatformServer(this.platformId)){return}

    if (isPlatformBrowser(this.platformId)) {
      if (this.localStorage.getItem('iMakeUpToken')) {
        this.authService.isAuthenticatedSubject.next(true);
      } else {
        this.authService.isAuthenticatedSubject.next(false);
      }
    }
    // this.categoryService.getCategories();
    this.accountService.getFavList();
    this.accountService.getAllCartItemList();
  }
  title = 'I-Makeup';
  notifierOptions: any = {
    animate: 'fromTop',
    position: ['top', 'right'],
    clickToClose: true,
    maxStack: 1,
  };
  ngOnDestroy() {
    this.subscription.unsubscribe();

  }

}
