import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { ShowFooterService } from 'src/app/core/services/show-footer.service';

@Component({
  selector: 'app-main-layout',
  templateUrl: './main-layout.component.html',
  styleUrls: ['./main-layout.component.scss']
})
export class MainLayoutComponent implements OnInit{
  isLoading: boolean = false
  constructor( private showFooter:ShowFooterService , private cdr : ChangeDetectorRef){}
  ngOnInit():void{
    this.showFooter.isLoading.subscribe(data=>{
      this.isLoading = data 
      this.cdr.detectChanges();
    })
  }

}
