import { isPlatformBrowser } from '@angular/common';
import { Component, PLATFORM_ID, inject } from '@angular/core';
import { Router } from '@angular/router';
import { MetaDataService } from 'src/app/core/services/meta-data/meta-data.service';
import { ShowFooterService } from 'src/app/core/services/show-footer.service';

@Component({
  selector: 'app-not-found-page',
  templateUrl: './not-found-page.component.html',
  styleUrls: ['./not-found-page.component.scss']
})
export class NotFoundPageComponent {
  platform_id:Object = inject(PLATFORM_ID);
  constructor(
    private router : Router ,
    private metaDataService: MetaDataService,
    private showFooter: ShowFooterService,
    ){
    this.metaDataService.updatePageTitle('404');
    this.showFooter.setShowFooterState()
    if(isPlatformBrowser(this.platform_id)){
      window.gtag('event', 'page_view', {
        page_title: 'NotFoundPage',
        page_location: window.location.href,
        page_path: window.location.pathname
      });
    }
  }

  toHome(){
    this.router.navigate(['/home.html']);
  }
}
