import { ActivatedRoute, NavigationEnd, Router, RouterModule } from '@angular/router';
import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  ElementRef,
  HostListener,
  Inject,
  LOCALE_ID,
  OnDestroy,
  OnInit,
  PLATFORM_ID,
  TransferState,
  ViewChild,
  inject,
  makeStateKey,
} from '@angular/core';
import { Subject, Subscription, catchError, debounceTime, of, switchMap, tap } from 'rxjs';
import { AuthService } from 'src/app/core/services/auth.service';
import { BackgroundColorService } from 'src/app/core/services/background-color.service';
import { environment } from 'src/environments/environment';
import { HttpService } from 'src/app/core/services/http.service';
import { CustomNotificationService } from 'src/app/core/services/custom-notification.service';
import { AccountService } from 'src/app/modules/account/services/account.service';
import { CommonModule, DOCUMENT, isPlatformBrowser, isPlatformServer } from '@angular/common';
import { ProductsService } from 'src/app/core/services/products.service';
import { contactInfo, mailObj } from 'src/app/shared/components/footer/footer.component';
import { OutputDto } from 'src/app/shared/models/interfaces/output-dto';
import { Product } from 'src/app/shared/models/interfaces/product';
import { SearchNavService } from './search-nav.service';
import { ErrorHandlingService } from 'src/app/core/services/error-handling.service';
export let NavCategories$ = new Subject<any>();

@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.scss'],
})
export class NavbarComponent implements OnInit, AfterViewInit, OnDestroy {
  paths: {
    orderPath: string;
    favPath: string;
    addressesPath: string;
    paymentsPath: string;
    myAccountPath: string;
    helpPath: string;
  } = {
    orderPath: '/account/orders',
    favPath: '/account/favorites.html',
    addressesPath: '/account/addresses',
    paymentsPath: '/account/payments',
    myAccountPath: '/account/my-account.html',
    helpPath: '/help-and-support.html',
  };


  protected bgColorSubscription!: Subscription;
  protected authStateSubscription!: Subscription;
  allCategoriesBg: { 'background-color': string } = {
    'background-color': '#fff',
  };
  categoriesBg: { 'background-color': string } = { 'background-color': '#fff' };
  navbarBg : { 'background-color': string } = { 'background-color': '#fff' };
  currentTabIndex: number = 0;
  myTimeout: any;
  userLogged: boolean = false;
  hideCategoriesSection: any;
  currentRoute: string = '';
  isAuth: boolean = false;
  userName: string = '';
  hostUrl: any = environment.hostUrl;
  loadingArray = ['', '', '', '', '', ''];
  logoutMessage: any = $localize`:@@logoutMessage:Logout successful`;
  protected authSubscription!: Subscription;

  hoveredCategory: string = '';
  isListhovered: boolean = false;

  currentCategorySelected: string = '';
  isLoadingCategories: boolean = false;
  categories: any[] = [];
  mainCategories: any = {};
  mainCateName: string[] = [];
  error: boolean = false;

  isAllCategoriesHovered: string = '';
  @ViewChild('resultList') resultListRef !: ElementRef ;
  @ViewChild('overLayer') overLayerRef !: ElementRef ;
  @ViewChild('offsetContainer') offsetContainerRef !: ElementRef ;
  resultsTimeout : any ;
  imgSrc : string = './assets/images/login-icon.svg';
  $accountServiceSubscription : Subscription = new Subscription();
  routeChangeSubscription!: Subscription;
  isLoadingContactInfo:boolean = true;
  helpSupportTran = $localize`:@@helpSupport:Help And Support`;
  contactInfo:any;
  @ViewChild('resultList', { static: false }) resultList!: ElementRef;
  static isShowNavScrolled:boolean = false;

  get isShowNavScrolledVal(){
    return NavbarComponent.isShowNavScrolled;
  }
  get containerQueryEl(){
    return NavbarComponent.containerQuery;
  }
  platform_id:Object = inject(PLATFORM_ID);
  NAV_CATEGORIES = makeStateKey<{data?:any[]}>('navbar-categories');

  @ViewChild('categoriesLayout') allCategories!: ElementRef<HTMLDivElement>;
  @ViewChild('footerNav') footerNav!: ElementRef<HTMLDivElement>;
  @ViewChild('scrolledNav') scrolledNav!: ElementRef<HTMLDivElement>;
  @ViewChild('staticNav') staticNav!: ElementRef<HTMLDivElement>;

  constructor(
    @Inject(PLATFORM_ID) private platformId: Object,
    private account:AccountService, 
    protected productsService: ProductsService,
    protected _auth: AuthService,
    protected backgroundColor: BackgroundColorService,
    protected accountService: AccountService,
    protected changeDetection: ChangeDetectorRef,
    protected _api: HttpService,
    @Inject(LOCALE_ID) public locale: string,
    protected router: Router,
    protected notifications: CustomNotificationService,
    protected activatedRoute: ActivatedRoute,
    @Inject(DOCUMENT) private document: Document,
    private _transferState:TransferState,
    public searchNav: SearchNavService,
    private _errorHandling : ErrorHandlingService 
  ) {}
  cartCountItems: number = 0
  data: any
  ngOnInit(): void {
    if(isPlatformServer(this.platformId)){
      this.getAllCategories();
    }else if (isPlatformBrowser(this.platformId)){
      let res = this._transferState.get(this.NAV_CATEGORIES, {data:[]});
      this.data = res
      document.body.clientWidth < 1280 ? this.searchNav.callFromFooterNav = true : this.searchNav.callFromFooterNav = false ;
      if (res && res?.data && res?.data?.length > 0) {
        NavCategories$.next(res);
        this.responseOfAllCategories(res)
      } else {
        this.getAllCategories();
      }
    }
    this.activatedRoute.queryParams.subscribe(
      (param)=>{
        this.searchNav.search = param['search'] || ''
      }
    )
    this.bgColorSubscription = this.backgroundColor.backgroundColor$.subscribe(
      (color) => {
        this.allCategoriesBg = {
          'background-color': color?.navbarAllCategories,
        };
        this.navbarBg = { 'background-color': color?.navbarBg };
        this.categoriesBg = { 'background-color': color?.navbarItems };
      }
    );
    this.accountService.cartCountItem.subscribe((data)=>{
      this.cartCountItems = data
    })
    this.detectRoutes(this.router?.url);
    this.router.events.subscribe((event: any) => {
      if (event instanceof NavigationEnd) {
        if (this.router.url.indexOf('category') == -1) {
          this.currentCategorySelected =
            this.mainCateName?.length > 0 ? this.mainCateName[0] : '';
          this.isAllCategoriesHovered = this.mainCateName[0];
        } else {
          let slug: string = this.router.url.split('/')[3];
          this.currentCategorySelected = this.categories.filter(
            (category: any) => category?.slug == slug
          )[0]?.name;
          this.isAllCategoriesHovered = this.currentCategorySelected;
        }
        this.detectRoutes(event?.url);
      }
    });

    this.userName = this._auth.getUserObj()?.name;
    this.authSubscription = this._auth.isAuthenticatedSubject.subscribe(
      (isAuthenticated) => {
        this.userName = this._auth.getUserObj()?.name;
        this.isAuth = isAuthenticated;
      }
    );

    this.$accountServiceSubscription = this.accountService?.hideCategoryNav$.subscribe((resp:boolean)=>{
      this.hideCategoriesSection = resp;
    })
    if(contactInfo?.contact_email){
      this.contactInfo = contactInfo;
      this.isLoadingContactInfo = false;
    }else{
      mailObj.subscribe(res=>{
       this.isLoadingContactInfo = res?.isLoading;
       this.contactInfo = res?.data?.contacts;
      })
    }
    if(isPlatformBrowser(this.platform_id)){
      window.addEventListener('scroll',()=>{
        // const rect = this.resultList.nativeElement.getBoundingClientRect();
        // if(rect.top > 0){
        //     this.closeSearchResults();
        // }
        if(window.scrollY < 190 || NavbarComponent.isShowNavScrolled == false){
          this.updateNavScrolled();
        }
      })
    }

  }
  isShowMegaMenu:boolean = true;
  @HostListener('window:resize')
  onResize(): void {
    this.updateNavScrolled();
    if(document.body.clientWidth < 1280){
      this.isShowMegaMenu = false;
      this.searchNav.callFromFooterNav = true  ;
      NavbarComponent.containerQuery = this.footerNav.nativeElement
      NavbarComponent.containerQuery = this.footerNav?.nativeElement
    }else{
      this.isShowMegaMenu = true;
      this.searchNav.callFromFooterNav = false ;
    }
  }

  updateNavScrolled(){
    if(this.offsetContainerRef){
      this.offsetContainerRef.nativeElement.style.height = `168px`;
    }
    if(window.scrollY > 168 && document.body.clientWidth > 1280){
      NavbarComponent.isShowNavScrolled = true
    }else{
     NavbarComponent.isShowNavScrolled = false;
    }
    if(this.isShowMegaMenu){
      NavbarComponent.containerQuery = this.isShowNavScrolledVal ?  this.scrolledNav?.nativeElement : this.staticNav?.nativeElement
    }
  }
  getAllCategories() {
    // if(isPlatformServer(this.platform_id)){return}
    this.isLoadingCategories = true;
    // incase server rendering
    this._api.getReq('/descendant-categories?parent_id=1').subscribe(
      (res) => {
        NavCategories$.next(res);
        if (isPlatformServer(this.platformId)) {
          this._transferState.set(this.NAV_CATEGORIES, res)
        }
        this.responseOfAllCategories(res);
      },
      (err) => {
        this.error = true;
        this._errorHandling.errorHandling(err , true)
        this.isLoadingCategories = false;
      }
    );
  }

  responseOfAllCategories(res:any,slice:number = 0){
    if(res){
      this.categories = res?.data ? res?.data : []
    }
    if(slice){
      this.categories = this.categories ? this.categories.slice(0,slice) : [];
    }
    this.categories = this.categories?.filter((e) => {
        if (e.sub_categories.length > 0) {
          this.mainCateName.push(e.name);
          this.mainCategories[e.name] = e;
        }
        return e;
    });

    if (this.router.url.indexOf('category') == -1) {

      this.currentCategorySelected =
        this.mainCateName?.length > 0 ? this.mainCateName[0] : '';
      this.isAllCategoriesHovered = this.mainCateName[0];
    } else {

      let slug: string = this.router.url.split('/')[3];
      this.currentCategorySelected = this.categories.filter(
        (category: any) => category?.slug == slug
      )[0]?.name;
    }

    this.isLoadingCategories = false;
  }

  logout() {
    this.account.cartItemList = [];
    this.account.cartListSubject$.next([]);
    this.account.cartCountItem.next(0)
    this._auth.signOut();
    this.notifications.showNotification({
      type: 'success',
      message: this.logoutMessage,
    });
    this.router.navigate(['/home.html']);
  }


  static containerQuery:any;
  ngAfterViewInit(): void {
    // this.hideCategoriesSection =
    //   this.accountService.isAccountPage || this.accountService.isHelpPage;
    this.changeDetection.detectChanges();
    if(isPlatformBrowser(this.platform_id)){
      if(document.body.clientWidth < 1280){
        this.isShowMegaMenu = false;
        NavbarComponent.containerQuery = this.footerNav?.nativeElement
      }else{
        NavbarComponent.containerQuery = this.isShowNavScrolledVal ?  this.scrolledNav?.nativeElement : this.staticNav?.nativeElement
        this.isShowMegaMenu = true;
      }
      this.searchNav.overLay = this.overLayerRef?.nativeElement
      this.searchNav.settingSearch();
    }
    // document.querySelector('.dropdown-menu')?.addEventListener('click', function(event) {
    //     event.stopPropagation();
    // });
  }

  ngOnDestroy(): void {
    this.bgColorSubscription.unsubscribe();
    this.authStateSubscription?.unsubscribe();
    if(typeof this.authSubscription !== 'undefined'){
      this.authSubscription.unsubscribe();
    }
    this.$accountServiceSubscription?.unsubscribe();
    if(this.routeChangeSubscription){
      this.routeChangeSubscription.unsubscribe();
    }
  }

  mouseEnter() {
    if (this.myTimeout) {
      clearTimeout(this.myTimeout);
    }
    this.allCategories.nativeElement.classList.add('show')
  }
  mouseLeave() {
    this.myTimeout = setTimeout(() => {
      this.allCategories.nativeElement.classList.remove('show')
    }, 100);
  }
  changeCategory(key: string) {
    this.currentCategorySelected = key;
  }
  openCategoriesPopup() {
    this.allCategories.nativeElement.classList.add('show')
  }
  closeCategoriesPopup() {
    this.allCategories.nativeElement.classList.remove('show')
    this.containerQueryEl.querySelectorAll('.sub-categories').forEach((category:any)=>{
      category.classList.remove('show');
    });
    this.productsService.$productsFiltersSubscription.next([]);
  }

  openAuthPopup() {
    this._auth.authPopup.next({ isOpen: true, form: 'signIn' });
    // this.closeProfileMenu();
  }

  detectRoutes(url: any) {
    if (url.includes(this.paths.orderPath)) {
      this.currentRoute = 'order';
    } else if (url.includes(this.paths.favPath)) {
      this.currentRoute = 'fav';
    } else if (url.includes(this.paths.addressesPath)) {
      this.currentRoute = 'addresses';
    } else if (url.includes(this.paths.myAccountPath)) {
      this.currentRoute = 'myAccount';
    } else if (url.includes(this.paths.helpPath)) {
      this.currentRoute = 'help';
    } else if (url.includes(this.paths.paymentsPath)) {
      this.currentRoute = 'payment';
    } else {
      return;
    }
  }

  switchLanguage() {
    if (this.locale == 'ar') {
      window.location.href = this.hostUrl + '/en' + this.router.url;
      localStorage.setItem('current_lang', 'en-US');
    } else {
      window.location.href = this.hostUrl + '/ar' + this.router.url;
      localStorage.setItem('current_lang', 'ar');
    }
  }

  mySubCateTimeout:any;
  linkHovered(categoryName: string) {
    if (this.mySubCateTimeout) {
      clearTimeout(this.mySubCateTimeout);
    }
    this.hoveredCategory = categoryName;
  }
  linkLeave() {
    this.mySubCateTimeout = setTimeout(() => {
      this.hoveredCategory = '';
    }, 200);
  }
  goToProduct(product:any){
    this.searchNav.showResultPopUp = false ;
    this.searchNav.searchResults = [];
    this.searchNav.search = '';
    this.overLayerRef.nativeElement.classList.remove('d-none');
  }

  originalPrice(product: Product){
    if(product?.special_price){
      if(product?.price?.toString().includes('.')){
        return `${(product?.price?.toString() || "")!.split(".")[0] ?
                  (product?.price?.toString() || "")!.split(".")[0] :
                  '0'},${(product?.price?.toString() || "")!.split(".")[1] ?
                  (product?.price?.toString() || "")!.split(".")[1]?.slice(0, 2)!.padEnd(2,'0') :
                  '00'}`
      }
      else{
        return `${product?.price},00` ;
      }
    }
    else{
      return 0;
    }
  }

  currentPrice(product: Product){
    if(product?.special_price){
        if(product?.special_price?.toString().includes('.')){
          return `${(product?.special_price?.toString() || "")!.split(".")[0] ?
                    (product?.special_price?.toString() || "")!.split(".")[0] :
                    '0'},${(product?.special_price?.toString() || "")!.split(".")[1] ?
                    (product?.special_price?.toString() || "")!.split(".")[1]?.slice(0, 2)!.padEnd(2,'0') :
                    '00'}`
        }else{
          return `${product?.special_price},00` ;
        }
    }
    else {
        if(product?.price?.toString().includes('.')){
          return `${(product?.price?.toString() || "")!.split(".")[0] ?
                    (product?.price?.toString() || "")!.split(".")[0] :
                    '0'},${(product?.price?.toString() || "")!.split(".")[1] ?
                    (product?.price?.toString() || "")!.split(".")[1]?.slice(0, 2)!.padEnd(2,'0') :
                    '00'}`
        }else{
          return `${product?.price},00` ;
        }
      }
  }
  showListElement(){
    let list = this.resultListRef.nativeElement ;
    if(this.resultsTimeout){
      clearTimeout(this.resultsTimeout)
    }
    list.classList.remove('d-none') ;
  }
  leaveSearch(){
    let list = this.resultListRef.nativeElement ;
    this.resultsTimeout = setTimeout(()=>{
      list.classList.add('d-none') ;
    },200)
  }
  suggestProducts(){
    if(this.searchNav.search !== ''){
      this.searchNav.searchTerms.next(this.searchNav.search);
    }else{
      this.searchNav.searching = false ;
      this.searchNav.searchResults = [] ;
      this.searchNav.noResults = false ;
      this.searchNav.searchError = '' ;
    }
  }
  @HostListener('document:click', ['$event'])
  handleClickOutside(event: Event) {
    if (this.resultList && !this.resultList.nativeElement.contains(event.target)) {
      this.closeSearchResults();
    }
  }

  openSearchResults() {
    // this.resultList.nativeElement.style.display = 'block';
    this.searchNav.showResultPopUp = true;
  }

  closeSearchResults() {
    this.searchNav.showResultPopUp = false;
    // this.resultList.nativeElement.style.display = 'none';
  }
}

