import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  OnDestroy,
  OnInit,
  Inject,
  LOCALE_ID,
  inject,
  PLATFORM_ID
} from '@angular/core';
import { NavigationEnd, Router, RouterModule } from '@angular/router';
import { Subject, Subscription } from 'rxjs';
import { AuthService } from 'src/app/core/services/auth.service';
import { HttpService } from 'src/app/core/services/http.service';
import { CategoryService } from 'src/app/core/services/category.service';
import { AccountService } from 'src/app/modules/account/services/account.service';
import { CommonModule, getLocaleDirection, isPlatformServer } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AccordionModule } from 'ngx-bootstrap/accordion';
import { NgxSkeletonLoaderModule } from 'ngx-skeleton-loader';
import { SmallFooterComponent } from '../small-footer/small-footer.component';
import { ValidUrlPipe } from '../../pipes/valid-url.pipe';
import { NavigateUrlDirective } from '../../directives/navigate-url.directive';
import { SortPipe } from '../../pipes/sort.pipe';
import { ErrorHandlingService } from 'src/app/core/services/error-handling.service';
export let contactInfo:any = {}
export const mailObj:Subject<{isLoading:boolean,data:any}> = new Subject<{isLoading:boolean,data:any}>();
@Component({
  standalone:true,
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss'],
  imports:[
    CommonModule,
    RouterModule,
    FormsModule,
    ReactiveFormsModule,
    AccordionModule,
    NgxSkeletonLoaderModule,
    SmallFooterComponent,
    NavigateUrlDirective,
    SortPipe,
  ]
})
export class FooterComponent implements OnInit, AfterViewInit, OnDestroy {
  accountPage: any;
  isFooterLoading: boolean = true;
  contacts: any = {};
  footerData: any = {};
  isContactLinksLoading: boolean = true;
  loadingSchema:any = ['','','','','']
  isAuth: boolean = false;
  private authSubscription!: Subscription;
  private categoriesSubscription!: Subscription;
  localeDir !: string ;
  platform_id:Object = inject(PLATFORM_ID);
  constructor(
    private accountService: AccountService,
    private changeDetection: ChangeDetectorRef,
    private router: Router,
    private _auth: AuthService,
    private _api: HttpService,
    private _errorHandling : ErrorHandlingService ,
    @Inject(LOCALE_ID) public lang: string
  ) {}
  ngOnInit(): void {
    if(isPlatformServer(this.platform_id)){return}
    this.getAllFooterData();
    this.getContactLinks();
    this.authSubscription = this._auth.isAuthenticatedSubject.subscribe(
      (isAuthenticated) => {
        this.isAuth = isAuthenticated;
      }
    );
    this._auth.checkUserAuth();
    this.isAuth = this._auth.isAuth;
    this.localeDir = isPlatformServer(this.platform_id) ? 'rtl' : getLocaleDirection('ar-EG');
  }
  ngAfterViewInit(): void {
    this.accountPage = this.accountService.isAccountPage;
    this.router.events.subscribe((params) => {
      let currentUrl = this.router.url;
      if (params instanceof NavigationEnd) {
        if (currentUrl.includes('account')) {
          this.accountPage = true;
          // this.accountService.hideCategoryNav$.next(true);

        } else {
          this.accountPage = false;
          // this.accountService.hideCategoryNav$.next(false);

        }
      }
    });
    this.changeDetection.detectChanges();
  }
  navigate() {
    this.router.navigate(['/gift-card.html']);
    window.scrollTo(0, 0);
  }

  openAuthPopup(form: any) {
    this._auth.authPopup.next({ isOpen: true, form: form });
  }

  ngOnDestroy(): void {
    if(isPlatformServer(this.platform_id)){return}
    this.authSubscription.unsubscribe();
    if (this.categoriesSubscription) {
      this.categoriesSubscription.unsubscribe();
    }
  }


  getAllFooterData(){
    this.isFooterLoading = true;
    this._api.getReq(`/themes?type=footer_links`).subscribe(res=>{
      this.footerData = res?.data[0]?.options || {};
      // this.footerData = Object.keys(options).map((option)=> options[option]);
      this.isFooterLoading = false;
    },err=>{
      this._errorHandling.errorHandling(err) ;
      this.isFooterLoading = false;
    })
  }

  getContactLinks() {
    this.isContactLinksLoading = true;
    mailObj.next({isLoading:true,data:{}})
    this._api.getReq('/core-configs/footer-contact-links').subscribe(
      (res) => {
        this.isContactLinksLoading = false;
        this.contacts = res?.data;
        mailObj.next({isLoading:false,data:this.contacts})
        contactInfo =  this.contacts;
      },
      (err) => {
        mailObj.next({isLoading:false,data:{}})
        this._errorHandling.errorHandling(err,true) ;
        this.isContactLinksLoading = false;
      }
    );
  }
}
