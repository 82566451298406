<div class="container-faqs">

  <div class="faqs">

    <div class="header">
      <h3 i18n="@@answerFAQS" class="title">Answers to frequently asked questions</h3>
      <div class="search-bar">
        <div class="search-icon"><img src="./assets/images/search.svg" alt="search-icon"></div>
        <input type="text"
        [(ngModel)]="searchKeyword"
        (input)="search()"
         class="form-control" name="" id="" i18n-placeholder="@@search" placeholder="Search">
      </div>
    </div>

    <div class="content">
      <div class="tabs-categories-container">
       <div class="tabs-container" *ngIf="!errorMsg && !isLoadingFaqsCategories && categories.length > 0">
        <span *ngFor="let category of categories" 
        [class.active]="currentTab === category?.id"
        (click)="onChangeTab(category)">
        {{ category?.name }}
      </span>
      </div>
      <div class="tabs-container"  *ngIf="isLoadingFaqsCategories">
        <div *ngFor="let item of ['','','','']">
          <ngx-skeleton-loader count="1" [theme]="{
          'border-radius': '5px',
            height: '40px',
            width:'130px',
        }"></ngx-skeleton-loader>
        </div>
      </div>
      </div>
  
      <div class="content-tab">

        <div *ngIf="!errorMsg && !loadingData && (faqItemsList || [])!.length > 0 && categories?.length > 0" class="faq-list">
          <accordion [isAnimated]="true">
            <div *ngFor="let faqItem of faqItemsList" class="faq-content">
              <accordion-group #test>
                <button class="btn-block justify-content-between d-flex w-100  shadow-none" accordion-heading type="button">
                  <div class="pull-left float-left">{{faqItem?.question}} </div>
                  <span class="float-right pull-right">{{ test.isOpen ? '-':'+' }}</span>
                </button>
                {{faqItem?.answer}}
              </accordion-group>
            </div>
          </accordion>
        </div>

        <div *ngIf="loadingData" class="d-flex flex-wrap justify-content-between">
          <div class="faq-loader" *ngFor="let item of ['','','','']">
            <ngx-skeleton-loader count="1" [theme]="{
            'border-radius': '5px',
            height: '40px',
            'margin-inline-end':'10px'
          }"></ngx-skeleton-loader>
          </div>
        </div>

        <div *ngIf="!loadingData && errorMsg" class="msg">
          <h1 class="d-flex error pb-5">
            <i class="fa-solid fa-circle-exclamation"></i>
            {{errorMsg}}
          </h1>
        </div>
        <div *ngIf="!loadingData && (faqItemsList || [])!.length == 0" class="msg">
          <h1 class="d-flex pb-5 empty" >
            <i class="fa-solid fa-circle-exclamation"></i>
            {{emptyMsg}}
          </h1>
        </div>

      </div>


    </div>


  </div>

</div>
