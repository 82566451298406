import { CommonModule } from '@angular/common';
import { Component, Input, OnInit, TemplateRef } from '@angular/core';
import { ngxLoadingAnimationTypes, NgxLoadingModule } from 'ngx-loading';
const PrimaryWhite = '#9F1E64';
const SecondaryGrey = '#9F1E64';
const PrimaryRed = '#dd0031';
const SecondaryBlue = '#1976d2';
@Component({
  standalone:true,
  selector: 'app-ngx-loader',
  templateUrl: './ngx-loader.component.html',
  styleUrls: ['./ngx-loader.component.scss'],
  imports:[
    CommonModule,
    NgxLoadingModule,
  ]
})
export class NgxLoaderComponent implements OnInit {
  @Input() loading=false;
  public ngxLoadingAnimationTypes = ngxLoadingAnimationTypes;
  public primaryColour = PrimaryWhite;
  public secondaryColour = SecondaryGrey;
  public coloursEnabled = false;
  public loadingTemplate!: TemplateRef<any>;
  public config = {
    animationType: ngxLoadingAnimationTypes.none,
    primaryColour: this.primaryColour,
    secondaryColour: this.secondaryColour,
    tertiaryColour: this.primaryColour,
    backdropBorderRadius: '3px',
    fullScreenBackdrop:true,
  };
  constructor() { }

  ngOnInit(): void {
  }
}
