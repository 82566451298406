<div class="sign-up-container">


  <div class="sign-up-form">
    <i (click)="closePopup()" class="fa-solid fa-xmark close-icon" style="cursor: pointer;"></i>
    <div class="header-form"><h3 i18n="@@signup" class="title">Sign Up</h3></div>

    <form action="" [formGroup]="signUpForm" #form="ngForm" (ngSubmit)="send()" novalidate>
        <div class="form-group">
            <input
              type="text"
              formControlName="name"
              i18n-placeholder="@@name"
              placeholder="Name"
              class="form-control"
            />
            <app-form-errors
            [color]="'danger'"
            [form]="form"
            [control]="signUpForm.controls['name']"
            >
            </app-form-errors>

        </div>

        <!-- <div class="form-group">
            <input
              type="email"
              formControlName="email"
              placeholder="Email"
              i18n-placeholder="@@email"
              class="form-control"
            />
            <app-form-errors
            [color]="'danger'"
            [form]="form"
            [control]="signUpForm.controls['email']"
            >
            </app-form-errors>

        </div> -->

        <div class="form-group">
          <div class="position-relative">
            <span class="country-code">{{ lang === 'ar' ? '966+' : '+966' }}</span>
            <input
            type="text"
            formControlName="phone"
            placeholder="Phone Number"
            i18n-placeholder="@@phone"
            class="form-control ltr-arabic-input"
            mask="000 000 0000||00 000 0000"
            (maskFilled)="getMask()"
            [validation]="true"
            [dropSpecialCharacters]="false"
            (input)="applyMasking(signUpForm.get('phone')?.value)"
            />
          </div>
            <app-form-errors
            [color]="'danger'"
            [form]="form"
            [control]="signUpForm.controls['phone']"
            >
            </app-form-errors>

        </div>
        <button type="submit" [ngClass]="{'valid-form-btn': signUpForm?.valid}" class="secondary-btn md-btn" [disabled]="loadingSubmit">
            <span i18n="@@continue">Continue</span>
            <i *ngIf="loadingSubmit" class="fa-spin fas fa-spinner ms-2"></i>
        </button>
    </form>

    <div class="footer-form">
        <a (click)="openSignInPopup()" i18n="@@signIn">Sign In</a>
    </div>

  </div>

  <div class="left-side">
    <img src="./assets/images/auth-cover-min2.png" alt="cover">
  </div>

</div>
<div id="recaptcha-container"></div>
