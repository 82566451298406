import { isPlatformBrowser } from '@angular/common';
import { Component, OnDestroy, OnInit, PLATFORM_ID, inject } from '@angular/core';
import { Subject } from 'rxjs';
import { BackgroundColorService } from 'src/app/core/services/background-color.service';
import { MetaDataService } from 'src/app/core/services/meta-data/meta-data.service';
import { ShowFooterService } from 'src/app/core/services/show-footer.service';
import { AccountService } from 'src/app/modules/account/services/account.service';
import { contactInfo, mailObj } from 'src/app/shared/components/footer/footer.component';


@Component({
  selector: 'app-help-and-support',
  templateUrl: './help-and-support.component.html',
  styleUrls: ['./help-and-support.component.scss'],
})
export class HelpAndSupportComponent implements OnInit , OnDestroy{
  helpSupportTran = $localize`:@@helpSupport:Help And Support`;
  isLoading:boolean = true;
  footerData:any;
  waitTranslate = $localize`:@@wait:Wait`;
  platform_id:Object = inject(PLATFORM_ID);
  constructor(private _accountService: AccountService,
    private showFooter: ShowFooterService,
    private backgroundColor: BackgroundColorService,
    private metaDataService: MetaDataService) {
      if(isPlatformBrowser(this.platform_id)){
        window.gtag('event', 'page_view', {
          page_title: 'Help&Support',
          page_location: window.location.href,
          page_path: window.location.pathname
        });
      }
    _accountService.isHelpPage = true;
    this._accountService.hideCategoryNav$.next(true);
    this.metaDataService.updatePageTitle(`${this.helpSupportTran} | ${this.metaDataService.titleTranslate}`);
  }

  ngOnInit(): void {
    this.backgroundColor.setBackgroundColor({ home: '#FDFDFD', navbarAllCategories: '#F9F9F9', navbarItems: '#F9F9F9' , navbarBg:'#fff' })
    if(contactInfo?.contacts?.contact_email){
      this.isLoading = false;
      this.footerData = contactInfo
    }
    else{
      mailObj.subscribe(obj=>{
        this.isLoading = obj?.isLoading;
        this.footerData = obj?.data;
      });
    }
    this.showFooter.setShowFooterState()
  }

  ngOnDestroy(): void {
    this._accountService.hideCategoryNav$.next(false);
  }
}
