<div class="sign-in-container">


    <div class="sign-in-form">
        <i (click)="close()" class="fa-solid fa-xmark close-icon" style="cursor: pointer;"></i>
        <div class="header-form"><h3 i18n="@@signIn" class="title">Sign In</h3></div>

        <!-- <div class="register-by-social-media">
            <div class="social-media google"><img src="./assets/images/google.svg" alt="google"></div>
            <div class="social-media apple"><img src="./assets/images/apple.svg" alt="apple"></div>
            <div class="social-media twitter"><img src="./assets/images/twitter.svg" alt="twitter"></div>
        </div>

        <div i18n="@@or" class="separated-line">Or</div> -->

        <form action="" [formGroup]="signInForm" #form="ngForm" (ngSubmit)="send()" novalidate>
            <div class="form-group">
                <div class="position-relative">
                    <span class="country-code">{{ lang === 'ar' ? '966+' : '+966' }}</span>
                    <input
                    type="text"
                    formControlName="phone_number"
                    placeholder="Phone Number"
                    i18n-placeholder="@@phone"
                    class="form-control ltr-arabic-input"
                    mask="000 000 0000||00 000 0000"
                    (maskFilled)="getMask()"
                    [validation]="true"
                    [dropSpecialCharacters]="false"
                    (input)="applyMasking(signInForm.get('phone_number')?.value)"

                    />
                </div>
                <app-form-errors
                [color]="'danger'"
                [form]="form"
                [control]="signInForm.controls['phone_number']"
                >
                </app-form-errors>
                
                <button type="submit" [ngClass]="{'valid-form-btn': signInForm?.valid}" class="secondary-btn md-btn" [disabled]="loadingSubmit">
                    <span i18n="@@continue">Continue</span>
                    <i *ngIf="loadingSubmit" class="fa-spin fas fa-spinner ms-2"></i>
                </button>

            </div>
        </form>

        <div class="res">
            <img src="./assets/images/auth-cover-resp.png" alt="cover">
        </div>

        <div class="footer-form">
            <a i18n="@@createAccount" (click)="openSignUpPopup()">Create Account</a>
        </div>
      
    </div>

    <div class="left-side desk">
        <img src="./assets/images/auth-cover-min2.png" alt="cover">
    </div>
</div>
<div id="recaptcha-container"></div>
