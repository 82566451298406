import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { HttpService } from 'src/app/core/services/http.service';

@Injectable({
  providedIn: 'root',
})
export class CategoryService {
  categoriesSubject$ = new BehaviorSubject<{
    categories: [];
    isLoading: boolean;
    errorMessage: string | null;
  }>({ categories: [], isLoading: false, errorMessage: null });

  constructor(private _api: HttpService) {}

  getCategories() {
    return
    this.categoriesSubject$.next({
      categories: [],
      isLoading: true,
      errorMessage: null,
    });
    this._api.getReq('/categories/featured/menu_featured').subscribe({
      next: (res) => {
        this.categoriesSubject$.next({
          categories: res?.data || [],
          isLoading: false,
          errorMessage: null,
        });
      },
      error: (err) => {
        this.categoriesSubject$.next({
          categories: [],
          isLoading: false,
          errorMessage: err?.message,
        });
      },
    });
  }
}
