<div [hidden]="isLoading || serverErrorFetch" #page class="page-container"> 

</div>
<div *ngIf="isLoading" class="watingContainer container" style="margin-top:30px; margin-bottom: 90px;">
    <div class="row">
        <div class="col-sm-6">
            <ngx-skeleton-loader
                count="1"
                [theme]="{ 
                    'border-radius': '11px',
                    height: '400px',
                    width:'90%',
                }"
            ></ngx-skeleton-loader>
        </div>
        <div class="col-sm-6 text-end">
            <ngx-skeleton-loader
                count="1"
                [theme]="{ 
                    'border-radius': '11px',
                    height: '20px',
                    width:'90%',
                }"
            ></ngx-skeleton-loader>
            <ngx-skeleton-loader
                *ngFor="let item of loadingSchema"
                count="1"
                [theme]="{ 
                    'border-radius': '11px',
                    height: '20px',
                    width:'70%',
                }"
            ></ngx-skeleton-loader>
            <ngx-skeleton-loader
                count="1"
                [theme]="{ 
                    'border-radius': '11px',
                    height: '20px',
                    width:'90%',
                }"
            ></ngx-skeleton-loader>
        </div>
    </div>
</div>
<div *ngIf="notFound && !serverErrorFetch && !isLoading" class="container-not-found-page">
    <img src="./assets/images/404.svg" i18n-alt="@@notFoundImg" alt="not-found-img">
</div>
<div *ngIf="serverErrorFetch && !isLoading" class="d-flex justify-content-center align-items-center" style="height: 80vh;font-size: 24px; color:red; font-weight: 600;">
       {{serverErrorFetch}}
</div>
<div *ngIf="noData && !isLoading" class="d-flex justify-content-center align-items-center text-warning" style="height: 80vh;font-size: 24px;">
    There is no content
</div>