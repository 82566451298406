<div class="container-navbar" [ngStyle]="navbarBg">
  <div class="top-nav" [ngClass]="{'d-none': hideTopNav}">
    <!-- first section -->
    <div class="d-flex f-section">
      <div class="d-flex w-100 align-items-center" style="gap: 10px;">
        <div class="back-arrow" *ngIf="searchNav.searchResults.length > 0" (click)="searchNav.showResultPopUp=false;overLayer.style.display='none';searchNav.searchResults=[]"><img src="./assets/images/prev-arrow.svg" alt=""></div>
        <div class="search-bar" (click)="$event.stopPropagation();">
          <div class="position-relative search-inp p-0">
              <input
              #mobileSearchInput
              id="nav-search"
              type="search"
              enterkeyhint="search"
              i18n-placeholder="@@navSearchSentence"
              placeholder="What are you looking for ?"
              [(ngModel)]="searchNav.search"
              (keydown)="enterPressed($event)"
              (input)="suggestProducts()"
              (click)="openSearchResults()"
              />
              <!-- (keyup.enter)="logWord();resultList.style.display='none';overLayer.style.display='none';searchNav.searchResults=[];searchNav.routeWithSearchQueryParam()" -->
          <div
            class="icon-search"
            (click)="searchNav.routeWithSearchQueryParam();$event.cancelBubble = true;closeSearchResults()"
          >
            <img
              src="./assets/images/search.svg"
              i18n-alt="@@search"
              width="20px"
              alt="search"
              *ngIf="!searchNav.searching"
            />
            <i *ngIf="searchNav.searching" class="fa-spin fas fa-spinner mt-1"></i>
          </div>
          </div>
          <div #resultList [hidden]="!searchNav.showResultPopUp">
            <div *ngIf="searchNav.searchResults.length > 0 && !searchNav.searching" class="autocomplete-results results-found">
              <ul class="result-list">
                <li *ngFor="let result of searchNav.searchResults">
                  <a class="d-flex align-items-center" (click)="goToProduct(result)"
                  [routerLink]="['/p/' + (result?.category|productDetailsPath) ,result?.url_key + '.html' ]">
                    <img class="prod-img" [src]="result?.base_image?.small_image_url" />
                    <div>
                      <p>{{ result?.name }}</p>
                      <span  class="current-price">{{ currentPrice(result) }}
                        <span class="currency" i18n="@@SAR">SAR</span>
                      </span>
                    <span  class="original-price mx-2" *ngIf="originalPrice(result)"> {{ originalPrice(result) }}
                        <span class="currency" i18n="@@SAR">SAR</span>
                    </span>
                    </div>
                  </a>
                </li>
              </ul>
              <div [hidden]="!(searchNav.metaDataSearchResult?.total > searchNav.metaDataSearchResult?.per_page)" (click)="searchNav.showResultPopUp=false;searchNav.routeWithSearchQueryParam()" class="footer-result">
                <span i18n="@@showAllResults">Show All Results</span>
                <img src="./assets/icons/arrow-dropdown.svg" alt="">
              </div>
            </div>
            <div *ngIf="searchNav.noResults && !searchNav.searching" class="autocomplete-results no-results">
              <div class="result-list">
                <p class="no-result-word" i18n="@@emptyMsg">No results found</p>
              </div>
            </div>
            <div *ngIf="searchNav.searchError && !searchNav.searching" class="autocomplete-results results-error">
              <div class="result-list">
                <p class="no-result-word text-danger text-break" >
                  <span i18n="@@serverErrorMessage">{{searchNav.searchError}}</span></p>
              </div>
            </div>
          </div>
        </div>
        <a routerLink="/account/favorites.html" class="fav-icon">
          <img src="./assets/images/fav.svg" alt="">
        </a>
        <div class="switch-langs" dir="ltr" (click)="switchLanguage()">
          <span class="lang-name">{{ locale == 'ar' ? 'EN':'AR' }}</span>
          <img
            [src]="
              locale == 'ar'
                ? './assets/images/en-us.png'
                : './assets/images/ar-SA.svg'
            "
            i18n-alt="@@flag"
            alt="flag"
          />
        </div>
      </div>
    </div>

  </div>
</div>
<div #overLayer (click)="searchNav.showResultPopUp=false;overLayer.style.display='none'" class="over-layer d-none"></div>
<footer>
  <div class="footer-nav-container">
      <div class="footer-nav-body">
          <a class="footer-nav-link"
              routerLink="/home.html"
              routerLinkActive
              #home="routerLinkActive"
              [ngClass]="home.isActive ? 'active' : ''">
              <img src="./assets/images/footer-nav/{{home.isActive ? 'active-' : ''}}home.svg" alt="home" loading="lazy">
              <span i18n="@@home_page">Home</span>
          </a>
          <a  class="footer-nav-link"
              routerLink="/all-categories.html"
              routerLinkActive
              #categories="routerLinkActive"
              [ngClass]="categories.isActive ? 'active' : ''">
              <img src="./assets/images/footer-nav/{{categories.isActive ? 'active-' : ''}}categories.svg" alt="categories" loading="lazy">
              <span i18n="@@categories">Categories</span>
          </a>
          <a class="footer-nav-link"
              routerLink="/top-sale.html"
              routerLinkActive
              #best_seller="routerLinkActive"
              [ngClass]="best_seller.isActive ? 'active' : ''">
              <img src="./assets/images/footer-nav/{{best_seller.isActive ? 'active-' : ''}}stars.svg" alt="stars" loading="lazy">
              <span i18n="@@bestSellers">Best Sellers</span>
          </a>
          <a class="footer-nav-link"
              routerLink="/account/my-account.html"
              routerLinkActive
              #my_account="routerLinkActive"
              [ngClass]="my_account.isActive ? 'active' : ''">
              <img src="./assets/images/footer-nav/{{my_account.isActive ? 'active-' : ''}}account.svg" alt="person" loading="lazy">
              <span i18n="@@myAccount">My Account</span>
          </a>
          <a class="footer-nav-link position-relative"
              routerLink="/cart.html"
              routerLinkActive
              #cart="routerLinkActive"
              [ngClass]="cart.isActive ? 'active' : ''">
              <img src="./assets/icons/navbar/{{cart.isActive ? 'navbar-cart-icon' : 'navbar-cart-inactive-icon'}}.svg" alt="cart" loading="lazy">
              <div class="position-absolute cart-count" *ngIf="cartCountItems">{{cartCountItems}}</div>
              <span i18n="@@cart">Cart</span>
          </a>
      </div>
  </div>
</footer>
