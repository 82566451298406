import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { NotificationsService } from 'angular2-notifications';
import { AuthService } from './auth.service';
import { CustomNotificationService } from './custom-notification.service';

@Injectable({
  providedIn: 'root'
})
export class ErrorHandlingService {
  constructor(
    private notify: NotificationsService,
    private notifications: CustomNotificationService,
    private route:Router,
    private _auth :AuthService
  ) { }

  errorHandling(err: any , redirectToNotFoundPage? : boolean ) {
    let errorsKeys:any[] = [];
    if(err?.error?.errors){
      errorsKeys = Object.keys(err?.error?.errors);
    }

    if (err?.code == 422) {
      this.notifications.showNotification({
        type: 'lg-error',
        message: err?.error?.errors[errorsKeys[0]][0],
      });
    }else if (err?.status == 422) {
      if(errorsKeys?.length > 0){
        this.notifications.showNotification({
          type: 'lg-error',
          message: err?.error?.errors[errorsKeys[0]][0],
        });
      }else{
        this.notifications.showNotification({
          type: 'lg-error',
          message: err?.error?.message,
        });
      }

    }else if (err?.code == 420){
      this._auth.setUserObj(err?.error?.data);
      this.notify.warn("", err?.error?.message);
        this.route.navigate(['/auth/sign-up/verify-email']);
    } else if (err?.code == 421){
      this._auth.setUserToken(err?.error?.data?.access_token);
      this._auth.setUserObj(err?.error?.data?.user);
      this.notify.warn("", err?.error?.message);
        this.route.navigate(['/auth/select-plan']);
    }else if(err?.code == 423){
      this.notify.error("", err?.error?.message);
      this.route.navigate(['/auth/select-plan']);
    } else if (err?.status == 500 || err?.status == 404) {
        !redirectToNotFoundPage ? this.route.navigate(['/not-found']) : null;
        this.notifications.showNotification({
          type: 'lg-error',
          message: $localize`:@@serverErrorMessage:Something wrong happened, please try again later`,
        });
    }  else if (err?.error?.message) {
      this.notifications.showNotification({
        type: 'lg-error',
        message: err?.error?.message,
      });
    } else {
        this.notifications.showNotification({
          type: 'lg-error',
          message: $localize`:@@serverErrorMessage:Something wrong happened, please try again later`,
        });
    }

  }

}
