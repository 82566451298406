<div class="gift-card-container">
  <div class="heading">
    <img src="./assets/images/gift.png" i18n-alt="@@giftCard" alt="gift-card" />
    <h3 i18n="@@giftCards">Gift Cards</h3>
  </div>
  <div class="main-gift-card" >
    <div class="gift" *ngIf="!isLoadingGiftCartDesign" [ngStyle]="{ background: mainGiftCardColor }">
      <img src="./assets/images/gift-card.png" i18n-alt="@@giftCard" alt="gift-card" />
   </div>
  <!-- Start loader -->
   <div class="gif" *ngIf="isLoadingGiftCartDesign">
    <ngx-skeleton-loader
      [theme]="{
        'border-radius': '8px',
         height: '320px',
         width:'47vw',
        }"
    ></ngx-skeleton-loader>
  </div>
  <!-- End loader -->
  </div>
  <div class="gift-cards">
    <h5 i18n="@@chooseGiftCardDesign">Choose a gift card design</h5>
    <div class="gift-cards-options" *ngIf="!isLoadingGiftCartDesign">
      <div
        [ngClass]="{ 'gift-card': true, active: mainGiftCardColor === color }"
        *ngFor="let color of giftCardColors"
        (click)="changeCardColor(color)"
        [ngStyle]="{ background: color }"
      >
        <img src="./assets/images/gift-card.png" i18n-alt="@@giftCard" alt="gift-card" />
      </div>
    </div>
    <!-- Start loader -->
   <div  class="d-flex justify-content-between flex-wrap" *ngIf="isLoadingGiftCartDesign">
    <div *ngFor="let i of [1, 2, 3]">
      <ngx-skeleton-loader
        [theme]="{
          'border-radius': '8px',
           height: '100px',
           width:'150px',
          }"
      ></ngx-skeleton-loader>
    </div>
   </div>
  <!-- End loader -->
  </div>
  <div class="user-card-info-container">
    <form [formGroup]="giftCardForm" #form="ngForm" (keydown.enter)="$event.preventDefault()" (ngSubmit)="addGiftCard()">
      <div class="user-info">
        <div class="form-group recipient-name">
          <input
            type="text"
            class="form-control"
            placeholder="Recipient's Name"
            i18n-placeholder="@@recipientName"
            formControlName="recipient_name"
          />
          <app-form-errors
          [color]="'danger'"
          [form]="form"
          [control]="giftCardForm.controls['recipient_name']"
          >
        </app-form-errors>
        </div>
        <div class="form-group recipient-email">
          <input
            type="email"
            class="form-control"
            placeholder="Recipient's Email"
            i18n-placeholder="@@recipientEmail"
            formControlName="recipient_email"
          />
          <app-form-errors
          [color]="'danger'"
          [form]="form"
          [control]="giftCardForm.controls['recipient_email']"
          >
        </app-form-errors>
        </div>
        <div class="phone-number-container">
           <div class="form-group phone-number">
            <span class="country-code">{{lang == 'ar'?'966+':'+966'}}</span>
            <input
              type="text"
              class="form-control ltr-arabic-input "
              placeholder="Phone Number"
              i18n-placeholder="@@phoneNumber"
              formControlName="phone_number"
              mask="000 000 0000||00 000 0000"
              (maskFilled)="getMask()"
              [validation]="true"
              [dropSpecialCharacters]="false"
              (input)="applyMasking(giftCardForm.get('phone_number')?.value)"
            />
           </div>
          <app-form-errors
          class="error-message"
          [color]="'danger'"
          [form]="form"
          [control]="giftCardForm.controls['phone_number']"
        >
        </app-form-errors>
        </div>
        <div class="sender-name-container">
           <div class="form-group sender-name">
            <input
            type="text"
            [value]="isSenderNameHide ? '' : senderName"
            [(ngModel)]="senderName"
            name="senderName"
            class="form-control"
            placeholder="Sender's name"
            i18n-placeholder="@@senderName"
            formControlName="sender_name"
            (input)="isSenderNameHide = false"
          />
          <div class="checkmark-container">
            <span
              [ngClass]="{ checkmark: true, checked: isSenderNameHide }"
              (click)="toggleNameVisibility()"
            ></span>
            <label i18n="@@hideName">Hide Name</label>
          </div>
           </div>
           <app-form-errors
          class="error-message"
          [color]="'danger'"
          [form]="form"
          [control]="giftCardForm.controls['sender_name']"
        >
        </app-form-errors>
        </div>
        <div class="form-group">
          <textarea
            class="form-control"
            placeholder="Add your personal message?"
            i18n-placeholder="@@addYouPersonalMessage"
            formControlName="message"
          ></textarea>
          <app-form-errors
          [color]="'danger'"
          [form]="form"
          [control]="giftCardForm.controls['message']"
        >
        </app-form-errors>
        </div>
      </div>
      <div class="gift-card-value-container">
        <h6 i18n="@@giftCardValue">Gift card value</h6>
        <div class="gift-card-values">
          <div *ngFor="let value of giftCardValues"
            [class.selected]="value === selectedCardValue">
            <span (click)="selectCardValue(value)" class="price">{{ value }}</span>
          </div>
          <div class="other-price" [class.selected]="'other price' === selectedCardValue">
              <span (click)="toggleGiftCardOtherPrice()"> {{ otherPrice }}</span>
               <input *ngIf="'other price' === selectedCardValue"  type="number" class="form-control" 
                #customValueInput
               (input)="customValueInput.value = customValueInput.value < '0' ? '' : customValueInput.value; handleCustomGiftCardValue(customValueInput.value)">
          </div>
        </div>
        <p *ngIf="!value&&isSubmit" class="gift-value-message" >{{giftValueMessage}}</p>
        <p i18n="@@maxGiftCardValue" *ngIf="isMaxGiftCardValue" class="gift-value-message" >The maximum value for purchasing a gift card is 1000 SAR</p>
        <p i18n="@@minGiftCardValue" *ngIf="isMinGiftCardValue" class="gift-value-message" >The minimum value for purchasing a gift card is 30 SAR</p>

      </div>
      <button [disabled]="loadingSubmit" class="primary-btn lg-btn" type="submit">
        <span *ngIf="!isAddedGiftCard&&!isLoading" i18n="@@addToCard">Add to cart</span>
        <span *ngIf="isAddedGiftCard||isLoading" i18n="@@edit">Edit</span>
        <i *ngIf="loadingSubmit" class="fa-spin fas fa-spinner mx-2"></i>
      </button>
      <!-- <button class="primary-btn lg-btn" [routerLink]="['/cart.html']" routerLinkActive="router-link-active" i18n="@@addToCard">Add to cart</button> -->
    </form>
  </div>
</div>

<!-- Loader -->
<div class="gift-card-loader">
  <app-ngx-loader [loading]="isLoading"></app-ngx-loader>
</div>